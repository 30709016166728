export enum StatusCode {
  Success = '13',
}

export interface ProcessingResult {
  id: number;
  video_id: number;
  status_code: StatusCode;
  status_message: string;
  error_code: string;
  error_message: string;
  warning_code?: any;
  warning_message?: any;
  shoe_size?: any;
  shoe_width?: any;
  alternative_shoe_size?: any;
  foot_measurements?: any;
  best_fit_margin_offset?: any;
  is_completed: number;
  created_at: Date;
  updated_at: Date;
}

export interface UploadedVideo {
  id: number;
  user_id: number;
  name?: any;
  path_to_video: string;
  path_to_images: string[];
  partner_id: string;
  manufacturer_id?: any;
  model_id?: any;
  product_sku?: any;
  shoe_size?: any;
  is_processed: number;
  created_at: Date;
  updated_at: Date;
  deleted_at?: any;
  processing_result: ProcessingResult;
}

export type UploadedVideoListResponse = {
  data: {
    uploaded_videos: UploadedVideo[];
  };
};

export interface Fitting {
  id: number;
  uuid: string;
  user_id: number;
  uploaded_video: UploadedVideo;
  shoe_brand: string;
  shoe_sku: string;
  size_type: string;
  link: string;
  result?: any;
  result_size_record?: any;
  result_size?: any;
  completed: boolean;
  created_at: Date;
  updated_at: Date;
  deleted_at?: any;
}

export type FittingListResponse = {
  data: {
    fittings: Fitting[];
  };
};

