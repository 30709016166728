<app-card>
  <header>Sign up</header>
  <content>
    <form [formGroup]="regisrationForm" (ngSubmit)="onSubmit()">
      <app-input
        [group]="regisrationForm"
        type="text"
        placeholder="Email"
        controlName="email"
        autocomplete="email"
      ></app-input>
      <app-input
        [group]="regisrationForm"
        placeholder="Password"
        controlName="password"
        type="password"
        autocomplete="new-password"
      >
      </app-input>
      <app-input
        [group]="regisrationForm"
        placeholder="Confirm Password"
        controlName="confirmPassword"
        type="password"
        autocomplete="new-password"
      ></app-input>
      <small
        class="p-error"
        *ngIf="regisrationForm.hasError('passwordMismatch')"
      >
        Passwords do not match
      </small>
      <button
        pButton
        [loading]="loading"
        label="Sign up"
        type="submit"
        [disabled]="!regisrationForm.valid"
      ></button>
      <div class="flex items-center content-center">
        <span class="text-suttled">or</span>
        <button
          pButton
          class="p-button-text p-button-secondary-text"
          label="Sign in"
          type="button"
          [routerLink]="['/login']"
          queryParamsHandling="preserve"
        ></button>
      </div>

      <div class="line"></div>

      <button
        pButton
        class="p-button-text p-button-secondary-text"
        label="Restore password"
        type="button"
        [routerLink]="['/forgot-password']"
        queryParamsHandling="preserve"
      ></button>

      <button
        pButton
        *ngIf="hasGoBackButton"
        (click)="goBack()"
        class="p-button-tint"
        label="Go back"
        type="button"
      ></button>
    </form>
  </content>
</app-card>
