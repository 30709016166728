import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import * as Sentry from '@sentry/angular-ivy';

Sentry.init({
  dsn: 'https://e62ae38a9a6ddf31f8e9776aa82ea75d@o280857.ingest.sentry.io/4506104935153664',
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [
        'localhost',
        /^https:\/\/dev-app\.footsnap\.com\/api/,
        /^https:\/\/app\.footsnap\.com\/api/,
      ],
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

if (environment.production) {
  enableProdMode();
}

const env = (window as any)._env_ || {};

// load config from config.json add pass to angular
platformBrowserDynamic([
  {
    provide: 'config',
    useValue: {
      ...environment,
      ...env,
    },
  },
])
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
