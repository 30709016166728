import { TableLazyLoadEvent } from 'primeng/table';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { map } from 'rxjs/operators';
import { ShoesService } from 'src/app/api/services';
import { allColumns } from './config';
import { FilterMetadata } from 'primeng/api';

type ShoeResponse = {
  data: {
    shoes: {
      data: any[];
    };
  };
};

type ShoeFiltersResponse = {
  data: {
    shoe_fields: Record<string, any[]>;
  };
};

@UntilDestroy()
@Component({
  selector: 'app-admin-portal-page',
  templateUrl: './admin-portal-page.component.html',
  styleUrls: ['./admin-portal-page.component.scss'],
})
export class AdminPortalPageComponent implements OnInit {
  @ViewChild('fileInput') fileInput!: ElementRef<HTMLInputElement>;

  cols = allColumns;
  allColumns = allColumns;

  uploading = false;
  shoes: any;
  dataLoading = true;
  uploadError = '';

  filters: Record<string, any[]> = {};

  constructor(
    private shoeService: ShoesService,
    private snackBar: MatSnackBar,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.loadShoeFilters();
  }

  hasFilter(field: string) {
    return this.filters.hasOwnProperty(field);
  }

  isCadModelComplete(shoe: any) {
    const fieldForMeasuredCad = [
      'length',
      'width',
      'instep',
      'circumference',
      'height',
    ];

    return fieldForMeasuredCad.every((field) => !!shoe.last_cad?.[field]);
  }

  getFieldValue(shoe: any, field: string) {
    const fields = field.split('.');
    const value = fields.reduce((acc, field) => acc?.[field], shoe);
    return value;
  }

  loadShoeFilters() {
    this.shoeService
      .getShoeListFilters()
      .pipe(
        map((res: any) => res as ShoeFiltersResponse),
        untilDestroyed(this)
      )
      .subscribe((res) => {
        this.filters = {};
        Object.entries(res.data.shoe_fields).forEach(([key, value]) => {
          this.filters[key] = value.map((item: any) => ({
            label: item === null ? 'null' : item,
            value: item,
          }));
        });
      });
  }

  getSorting(data: TableLazyLoadEvent) {
    const hasSorting = data?.sortField && data?.sortOrder;
    const sortFields = hasSorting
      ? {
          sort: data.sortField ?? 'id',
          order: data.sortOrder === 1 ? 'asc' : 'desc',
        }
      : ({} as any);
    return sortFields;
  }

  getPagination(data: TableLazyLoadEvent) {
    const perPage = data?.rows ?? 10;
    const firstRecord = data?.first ?? 0;
    const page = Math.floor(firstRecord / perPage) + 1;

    return {
      page: page,
      per_page: perPage,
    };
  }

  getFilters(data: TableLazyLoadEvent) {
    return Object.entries(data?.filters ?? {}).reduce((acc, item) => {
      const [key, value] = item as [string, FilterMetadata];
      if (value.value) {
        acc[key] = value.value;
      }
      return acc;
    }, {} as any);
  }

  loadShoes(data: TableLazyLoadEvent) {
    console.log('data', data);
    this.dataLoading = true;

    this.shoeService
      .getShoeList({
        ...this.getPagination(data),
        ...this.getSorting(data),
        body: this.getFilters(data),
      })
      .pipe(
        map((res: any) => res as ShoeResponse),
        map((res: ShoeResponse) => res?.data?.shoes),
        untilDestroyed(this)
      )
      .subscribe((res) => {
        this.shoes = res;
        this.dataLoading = false;
      });
  }

  get selectedFile(): File | undefined {
    return this.fileInput?.nativeElement?.files?.[0];
  }

  selectArchive() {
    this.fileInput.nativeElement.addEventListener(
      'change',
      () => {
        this.cd.detectChanges();
      },
      {
        once: true,
      }
    );
    this.fileInput.nativeElement.click();
  }

  uploadArchive() {
    if (!this.selectedFile) {
      return;
    }
    this.uploading = true;
    this.uploadError = '';
    this.shoeService
      .uploadZip({ body: { zipFile: this.selectedFile } })
      .subscribe(
        () => {
          this.uploading = false;
          this.uploadError = '';
          this.fileInput.nativeElement.value = '';
          this.snackBar.open('Upload successful', 'Close', {
            duration: 2000,
          });
        },
        (err) => {
          this.uploading = false;
          console.log('err', err);
          this.uploadError =
            err?.error?.error?.errorMessage ?? 'Something went wrong';
          this.fileInput.nativeElement.value = '';
          this.snackBar.open(
            err?.error?.message ?? 'Something went wrong',
            'Close',
            {
              duration: 2000,
            }
          );
        }
      );
  }
}
