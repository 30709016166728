/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

type file = Blob;


@Injectable({
  providedIn: 'root',
})
export class ShoesService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation uploadZip
   */
  static readonly UploadZipPath = '/shoes/upload';

  /**
   * Upload Zip.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadZip()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadZip$Response(params: {
    context?: HttpContext
    body: {

/**
 * Zip file
 */
'zipFile': file;
}
  }
): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, ShoesService.UploadZipPath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Upload Zip.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `uploadZip$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadZip(params: {
    context?: HttpContext
    body: {

/**
 * Zip file
 */
'zipFile': file;
}
  }
): Observable<{
}> {

    return this.uploadZip$Response(params).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Path part for operation getShoeList
   */
  static readonly GetShoeListPath = '/shoes';

  /**
   * Get shoe list.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getShoeList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getShoeList$Response(params: {

    /**
     * Per page
     */
    per_page?: number;

    /**
     * Page
     */
    page?: number;

    /**
     * Sort
     */
    sort?: string;

    /**
     * Order
     */
    order?: 'asc' | 'desc';
    context?: HttpContext
    body: {

/**
 * Field for filtering 1
 */
'shoe_brand'?: Array<any>;

/**
 * Field for filtering 1
 */
'shoe_sku'?: Array<any>;

/**
 * Field for filtering 1
 */
'style'?: Array<any>;
}
  }
): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, ShoesService.GetShoeListPath, 'post');
    if (params) {
      rb.query('per_page', params.per_page, {});
      rb.query('page', params.page, {});
      rb.query('sort', params.sort, {"explode":false});
      rb.query('order', params.order, {"explode":false});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Get shoe list.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getShoeList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getShoeList(params: {

    /**
     * Per page
     */
    per_page?: number;

    /**
     * Page
     */
    page?: number;

    /**
     * Sort
     */
    sort?: string;

    /**
     * Order
     */
    order?: 'asc' | 'desc';
    context?: HttpContext
    body: {

/**
 * Field for filtering 1
 */
'shoe_brand'?: Array<any>;

/**
 * Field for filtering 1
 */
'shoe_sku'?: Array<any>;

/**
 * Field for filtering 1
 */
'style'?: Array<any>;
}
  }
): Observable<{
}> {

    return this.getShoeList$Response(params).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Path part for operation getShoeListFilters
   */
  static readonly GetShoeListFiltersPath = '/shoes/filters';

  /**
   * Get shoe list filters.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getShoeListFilters()` instead.
   *
   * This method doesn't expect any request body.
   */
  getShoeListFilters$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, ShoesService.GetShoeListFiltersPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Get shoe list filters.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getShoeListFilters$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getShoeListFilters(params?: {
    context?: HttpContext
  }
): Observable<{
}> {

    return this.getShoeListFilters$Response(params).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

}
