import {InstructionVideo} from '../interfaces/instruction-video';

export const enum SharedEnum {
  TimeForScan = 30000
}

export const enum ApiPath {
  UploadVideoUrl = '/uploadVideo',
  ProcessingVideoStatus = '/processingResult'
}

export const ArrayInstructionVideo: Array<InstructionVideo> = [
  {
    video: '/assets/media/FS_InstrctionVideo_v01.mp4',
    image: '/assets/images/preview.png',
    showImage: true
  }
]

export const PeriodsForImagesInstruction: number = 11;

export type InstructionStep = { text: string; imageUrl: string; soundUrl: string };

export const instructionsSteps: InstructionStep[] = [
  {
    text: 'Slowly move the phone and scan the BACK of the left foot.',
    imageUrl: 'assets/images/scanning/1.png',
    soundUrl: '1',
  },
  {
    text: 'Scan LEFT side of left foot.',
    imageUrl: 'assets/images/scanning/2.png',
    soundUrl: '2',
  },
  {
    text: 'Scan FRONT side of left foot.',
    imageUrl: 'assets/images/scanning/3.png',
    soundUrl: '3',
  },
  {
    text: 'Change hands.',
    imageUrl: 'assets/images/scanning/4.png',
    soundUrl: '4',
  },
  {
    text: 'Scan FRONT side of right foot.',
    imageUrl: 'assets/images/scanning/5.png',
    soundUrl: '5',
  },
  {
    text: 'Scan RIGHT side of right foot.',
    imageUrl: 'assets/images/scanning/6.png',
    soundUrl: '6',
  },
  {
    text: 'Slowly move the phone to the back of the Right foot and finish your scan.',
    imageUrl: 'assets/images/scanning/7.png',
    soundUrl: '7',
  },
];

export const ImagesUrlForPreload = [
  '/assets/icons/warning.png',
  '/assets/images/error-image.png',
  '/assets/images/small-tutorial/1.png',
  '/assets/images/small-tutorial/2.png',
  '/assets/images/small-tutorial/3.png',
  '/assets/images/small-tutorial/4.png',
  '/assets/images/small-tutorial/5.png',
  '/assets/images/small-tutorial/6.png',
  '/assets/images/small-tutorial/7.png',
];
