<div class="page">
  <!-- button to upload archive -->
  <div class="uploader">
    <input type="file" #fileInput accept="application/zip" />
    <div class="uploader-buttons">
      <button pButton (click)="selectArchive()">Select Archive</button>
      <button
        pButton
        (click)="uploadArchive()"
        [loading]="uploading"
        [disabled]="!selectedFile"
        [label]="uploading ? 'Uploading...' : 'Upload'"
      ></button>
    </div>
    <div class="uploader-files" *ngIf="selectedFile">
      Selected file: {{ selectedFile.name }}
    </div>

    <div class="uploader-error" *ngIf="uploadError">
      {{ uploadError }}
    </div>
  </div>

  <!-- table to display uploaded archives -->
  <div class="list">
    <p-table
      [columns]="cols"
      [value]="shoes?.data"
      [dataKey]="'id'"
      [paginator]="true"
      (onLazyLoad)="loadShoes($event)"
      [lazy]="true"
      [rows]="10"
      [rowsPerPageOptions]="[5, 10, 20]"
      [loading]="dataLoading"
      [totalRecords]="shoes?.total"
    >
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th></th>
          <th></th>
          <th *ngFor="let col of columns" [pSortableColumn]="col.field">
            {{ col.header }}
            <p-sortIcon [field]="col.field"></p-sortIcon>
          </th>
        </tr>
        <tr>
          <th></th>
          <th></th>
          <th *ngFor="let col of columns">
            <p-columnFilter
              *ngIf="hasFilter(col.field)"
              [field]="col.field"
              matchMode="in"
              [showMenu]="false"
            >
              <ng-template
                pTemplate="filter"
                let-value
                let-filter="filterCallback"
              >
                <p-multiSelect
                  [ngModel]="value"
                  [options]="filters[col.field]"
                  placeholder="Any"
                  (onChange)="filter($event.value)"
                >
                </p-multiSelect>
              </ng-template>
            </p-columnFilter>
          </th>
        </tr>
      </ng-template>
      <ng-template
        pTemplate="body"
        let-rowData
        let-expanded="expanded"
        let-columns="columns"
      >
        <tr>
          <td>
            <a href="#" [pRowToggler]="rowData">
              <i
                [ngClass]="
                  expanded
                    ? 'pi pi-chevron-circle-down'
                    : 'pi pi-chevron-circle-right'
                "
              ></i>
            </a>
          </td>
          <td>
            <div
              class="dot"
              [class.green]="isCadModelComplete(rowData)"
              [class.red]="!isCadModelComplete(rowData)"
            ></div>
          </td>
          <td *ngFor="let col of columns">
            <ng-container *ngIf="col.type === 'date'">
              {{
                getFieldValue(rowData, col.field) | date : "dd/MM/yyyy hh:mm:ss"
              }}
            </ng-container>
            <ng-container *ngIf="!col.type || col.type === 'text'">
              {{ getFieldValue(rowData, col.field) }}
            </ng-container>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="rowexpansion" let-rowData let-columns="columns">
        <tr>
          <td [attr.colspan]="columns.length + 1">
            <div class="grid p-fluid" style="font-size: 16px; padding: 20px">
              <div class="grid">
                <div class="col-12" *ngFor="let col of columns">
                  <b>{{ col.header }}:</b>
                  <span>
                    <ng-container *ngIf="col.type === 'date'">
                      {{
                        getFieldValue(rowData, col.field)
                          | date : "dd/MM/yyyy hh:mm:ss"
                      }}
                    </ng-container>
                    <ng-container *ngIf="!col.type || col.type === 'text'">
                      {{ getFieldValue(rowData, col.field) }}
                    </ng-container>
                  </span>
                </div>
              </div>
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <ng-template #loader>
    <div class="loader">
      <p-progressSpinner></p-progressSpinner>
    </div>
  </ng-template>
</div>
