import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { FittingsService, UploadService } from 'src/app/api/services';
import { Fitting, UploadedVideo } from '../../models';
import { QueryParamsService } from '../../service/query-params.service';

@Component({
  selector: 'app-measure-card',
  templateUrl: './measure-card.component.html',
  styleUrls: ['./measure-card.component.scss'],
})
export class MeasureCardComponent {
  @Input() type: 'measure' | 'match' | 'match-no-data' | 'match-loading' = 'measure';
  @Input() measurement: UploadedVideo | null = null;
  @Input() fitting: Fitting | null = null;
  @Output() closeFitting = new EventEmitter<void>();
  @Output() closeMeasurement = new EventEmitter<void>();

  renameCard = false;
  renaming = false;
  selectingFeet = false;
  closing = false;
  deletingScan = false;
  showDeleteScanDialog = false;

  constructor(
    private uploadService: UploadService,
    private fittingsService: FittingsService,
    private queryMapService: QueryParamsService,
    private snackBar: MatSnackBar,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  goToStore() {
    if (this.fitting) {
      window.location.href = this.fitting.link + `?shoesize=${this.fitting.result_size}`;
    }
  }

  canSelectShoe(): boolean {
    const params = this.queryMapService.getShoeParams();
    return (
      !!this.measurement?.id &&
      Object.keys(params).every((key) => !!params[key as keyof typeof params])
    );
  }

  selectShoe() {
    this.selectingFeet = true;
    this.fittingsService
      .addFitting({
        body: {
          uploaded_video_id: this.measurement?.id,
          ...this.queryMapService.getShoeParams(),
        },
      })
      .subscribe(
        () => {
          this.selectingFeet = false;
          this.router.navigate(['/matchings'], {
            queryParams: this.route.snapshot.queryParams,
            queryParamsHandling: 'merge',
          });
        },
        (err) => {
          this.selectingFeet = false;
          this.snackBar.open(
            err?.error?.message ?? 'Cannot select shoe',
            'OK',
            {
              duration: 2000,
            }
          );
        }
      );
  }

  rename(text: HTMLInputElement): void {
    this.renaming = true;
    const name = text.value;

    this.uploadService
      .updateUploadedVideo$Response({
        id: this.measurement?.id,
        body: {
          name: name,
        },
      })
      .subscribe(
        () => {
          text.value = '';
          this.measurement!.name = name;
          this.renaming = false;
          this.renameCard = false;
        },
        () => {
          this.snackBar.open('Cannot rename scan', 'OK', {
            duration: 2000,
          });
          text.value = '';
          this.renaming = false;
          this.renameCard = false;
        }
      );
  }

  deleteScan() {
    if (!this.measurement) {
      return;
    }

    this.deletingScan = true;
    this.uploadService.deleteUploadedVideo({ id: this.measurement.id }).subscribe(
      () => {
        this.deletingScan = false;
        this.closeMeasurement.emit();
      },
      (err) => {
        this.deletingScan = false;
        this.snackBar.open(
          err?.error?.message ?? 'Cannot delete scan',
          'OK',
          {
            duration: 2000,
          }
        );
      });
  }

  close() {
    if (!this.fitting) {
      return;
    }

    this.closing = true;
    this.fittingsService.deleteFitting({ id: this.fitting.id }).subscribe(
      () => {
        this.closing = false;
        this.closeFitting.emit();
      },
      (err) => {
        this.closing = false;
        this.snackBar.open(
          err?.error?.message ?? 'Cannot close fitting',
          'OK',
          {
            duration: 2000,
          }
        );
      }
    );
  }
}
