import { Component, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { token$ } from 'src/app/repos/auth.repository';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  isLoggedIn$ = token$.pipe(map((token) => !!token));

  get logoUrl(): string {
    if (this.config.envName === 'develop') {
      return '/assets/images/footsnap_logo_red.png';
    }
    return '/assets/images/footsnap_logo.png';
  }

  constructor(
    private router: Router,
    @Inject('config')
    private config: any
  ) {}

  goHome(): void {
    this.router.navigate(['']);
  }
}
