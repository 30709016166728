<app-header></app-header>
<div class="container" [style]="{display:  'block'}">
  <div class="instructions-info">
    <!--<h2 class="top-title">Step 3 of 3</h2>-->
    <div class="result-processing-wrapper"
         *ngIf="processingResult && processingResult.status === 'pending' && status === 'success'">
      <div class="top-info centered-wrapper">
        <img class="loading-image" src="/assets/images/top-loading-image.png" alt="Loading image">
      </div>
      <div class="top-info__list-wrapper">
        <div class="result-info">
          <h5>Processing!</h5>
          <p class="result-info__message">
            Hooray! Your scan has been <span class="color-green">saved</span> successfully and it's now <span class="color-green">being processed</span> (your request Id: {{videoID}}). <!--Please enable the browser notifications so you can <span class="color-green">receive</span> the result-->
          </p>
          <div class="result-info__image centered-wrapper">
            <img src="/assets/images/upload-success.png" alt="Progress image">
          </div>
          <div class="result-info__processing-step centered-wrapper">
            <p class="text-center"><span class="mr-dots">{{processingResult.processingStep}}</span>
              <span class="processing-step-loader ml-dots">
                <span class="first-dot"></span>
                <span class="second-dot"></span>
                <span class="third-dot"></span>
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="processingResult && processingResult.status && processingResult.status === 'success'"
         [@inOutAnimation] class="result-processing-wrapper result-success-wrapper">
      <div class="top-info centered-wrapper">
        <img class="loading-image" src="/assets/images/top-result-image.png" alt="Result image">
      </div>
      <div class="top-info__list-wrapper">
        <div class="result-info">
          <h6>Recommended shoe size <span class="color-green">{{processingResult.shoeSize}}</span></h6>
          <!--for deploy remove all static texts-->
          <h6 *ngIf="processingResult.alternativeShoeSize" class="color-green">Alternative shoe size {{processingResult.alternativeShoeSize}}</h6>
          <p class="result-info__message">Great! We successfully determined the perfect shoe size for you. Please <span class="color-green">return</span> to the web shop to <span class="color-green">finalize</span> your purchase</p>
          <p *ngIf="processingResult.warningMessage" class="result-info__warning-message color-red">
           <!-- {{processingResult.warningMessage}} -->
            Oh! Paper shape seems deformed... You can try again or use our recommendations
            <span class="emoji-container"><img src="/assets/icons/sad-face.png" alt="Sad face emoji"></span>
          </p>

        </div>
      </div>
      <div class="result-success-buttons">
        <button class="orange-button"  (click)="checkToStartRecording(!!processingResult.alternativeShoeSize)" [disabled]="!processingResult.alternativeShoeSize && disabledSiteLink">{{!!processingResult.alternativeShoeSize ? "Record video" : "Go back to the site"}}</button>
        <button *ngIf="processingResult.alternativeShoeSize" class="green-button" (click)="goToSite()" [disabled]="disabledSiteLink">Go back to the site</button>
      </div>
    </div>

    <div *ngIf="(processingResult && processingResult.status && processingResult.status === 'failed') || (status && status !== 'success')" class="result-error-wrapper">
      <div class="result-error">
        <div class="result-error__title">
          <h5>Error</h5>
          <img [src]="helperService.images.get('/assets/icons/warning.png') ?
          helperService.images.get('/assets/icons/warning.png') : '/assets/icons/warning.png'" alt="Warning icon">
        </div>
        <p *ngIf="!(processingResult && processingResult.status && processingResult.status === 'failed')" class="result-error__message">
          <span *ngIf="status !== '0'">Sorry, we <span class="color-green">couldn't save your video</span> for processing. Error:
          {{status}}</span>
          <span *ngIf="status === '0'">
            Sorry, we couldn't save your video because your device has lost Internet connection. Please reconnect and click "Try again" button
          </span>
          <!-- Please send error
          code {{status}} to
          <a href="mailto: info@footsnap.com" target="_blank">info@footsnap.com</a>-->
        </p>
        <p *ngIf="processingResult && processingResult.status && processingResult.status === 'failed'" class="result-error__message">
          <span *ngIf="status !== '0'">Sorry, we <span class="color-green">can't make the recommendation</span><span *ngIf="processingResult.errorMessage"> (Reason: {{processingResult.errorMessage}})</span>. Please record a new video and try again</span>
          <span *ngIf="status === '0'">Sorry, we couldn't show processing result because your device has lost Internet connection. Please reconnect and click "Try again" button</span>
        </p>
        <div class="result-error__image centered-wrapper">
          <img [src]="helperService.images.get('/assets/images/error-image.png') ? helperService.images.get('/assets/images/error-image.png') : '/assets/images/error-image.png'" alt="Error image">
        </div>

        <button *ngIf="status !== '0'" (click)="startRecording()" class="orange-button">Record video</button>
        <button *ngIf="status === '0' && !(processingResult && processingResult.status && processingResult.status === 'failed')" (click)="uploadFile()" class="orange-button">Try again</button>
        <button *ngIf="status === '0' && processingResult && processingResult.status && processingResult.status === 'failed'" (click)="retryProcessing()" class="orange-button">
          <span>Try again</span><span *ngIf="showButtonLoading" class="processing-step-loader">
                <span class="first-dot white-dot"></span>
                <span class="second-dot white-dot"></span>
                <span class="third-dot white-dot"></span>
              </span>
        </button>
      </div>
    </div>
  </div>
</div>
