import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { ArrayInstructionVideo } from '../../constants/shared-enum';
import { InstructionVideo } from '../../interfaces/instruction-video';
import { MediaService } from '../../shared/services/media.service';

@Component({
  selector: 'app-instructions-first-step',
  templateUrl: './instructions-first-step.component.html',
  styleUrls: ['./instructions-first-step.component.scss'],
})
export class InstructionsFirstStepComponent implements OnInit {
  showCamera: boolean = false;
  instructionsVideo: Array<InstructionVideo> = ArrayInstructionVideo;

  form = {
    light: false,
    foot: false,
    paper: false,
    stand: false,
  };

  @ViewChild('startButton', { static: true }) startButton!: ElementRef<any>;
  videoLoaded = false;
  scrollDownVisible = true;

  constructor(
    private mediaServices: MediaService,
    private readonly router: Router,
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    if (this.activatedRoute.snapshot.queryParams['showCamera'] === 'true') {
      setTimeout(() => {
        this.startRecording();
      }, 100);
    }

    // intersection observer if button in view
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5,
    };

    const callback = (entries: IntersectionObserverEntry[]) => {
      // video is loaded
      if (this.videoLoaded) {
        if (entries[0]?.isIntersecting) {
          this.scrollDownVisible = false;
          observer.disconnect();
        }
      }
    };

    const observer = new IntersectionObserver(callback, options);
    observer.observe(this.startButton.nativeElement);
  }

  startRecording() {
    const cameraOptions = this.mediaServices.getCameraOptions();
    navigator.mediaDevices.getUserMedia(cameraOptions).then(
      async (stream) => {
        this.mediaServices.setStream(stream);
        await this.mediaServices.blockOrientation();
        this.showCamera = true;
      },
      (err) => {
        const message = err.constraint
          ? `Camera don't support ` + err.constraint
          : 'Please, allow access to camera';
        this._snackBar.open(message, 'x', {
          duration: 2000,
          verticalPosition: 'top',
        });
      }
    );
  }

  saveFile(event: { file: Blob; paperPercent: number }): void {
    this.hideCamera();
    this.mediaServices.saveFile(event.file);
    this.router.navigate(['video-recording'], {
      queryParams: { paperPercent: event.paperPercent },
    });
  }

  hideCamera(): void {
    this.showCamera = false;
    this.mediaServices.unblockOrientation();
  }

  onVideoLoaded() {
    this.videoLoaded = true;
  }
}
