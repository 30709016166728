import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
})
export class InputComponent {
  @Input() group: UntypedFormGroup | null = null;
  @Input() controlName = '';
  @Input() placeholder = '';
  @Input() type = 'text';
  @Input() autocomplete = 'off';

  get control() {
    return this.group?.get(this.controlName);
  }

  get errors(): string[] {
    const errors = this.group?.get(this.controlName)?.errors;
    if (!errors) {
      return [];
    }

    return Object.keys(errors).map((key) => {
      switch (key) {
        case 'required':
          return `${this.placeholder} is required`;
        case 'email':
          return `Should be a valid email`;
        case 'minlength':
          return `Must be at least ${errors[key].requiredLength} characters`;
        case 'maxlength':
          return `Must be at most ${errors[key].requiredLength} characters`;
        case 'pattern':
          return `Should match the pattern ${errors[key].requiredPattern}`;
        case 'password':
          return errors[key];
        default:
          console.log('Validator not found', key);
          return '';
      }
    });
  }
}
