import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MediaService} from '../../shared/services/media.service';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {UploadFileComponent} from '../../shared/components/upload-file/upload-file.component';

declare var MediaRecorder: any;

@Component({
  selector: 'app-instructions-second-step',
  templateUrl: './instructions-second-step.component.html',
  styleUrls: ['./instructions-second-step.component.scss']
})
export class InstructionsSecondStepComponent implements OnInit, OnDestroy {
  private readonly thresholdPercent = 15;

  checkFile: boolean = false;
  iosOS: boolean = false;
  showRedo = false;
 
  constructor(
    private mediaServices: MediaService,
    public dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    public router: Router,
    private _snackBar: MatSnackBar
  ) {
    this.iosOS = this.mediaServices.detectIOS();
  }

  ngOnInit(): void {
    const paperPercentQuery = this.activatedRoute.snapshot.queryParams['paperPercent']
    this.showRedo = parseInt(paperPercentQuery, 10) < this.thresholdPercent;
    this.setCheckFile();
    this.setLinkToVideo();
  }

  uploadFile() {
    if(!this.checkFile) {
      this._snackBar.open("Please, make video", 'x', {
        duration: 2000,
        verticalPosition: 'top',
      });
      return;
    }
    let dialogRef = this.dialog.open(UploadFileComponent, {
     hasBackdrop: true
    });

    dialogRef.afterClosed().subscribe(res => {
      this.goToResult(res);
    })
  }

  goToResult(message: string): void {
    this.mediaServices.setUploadResponse(message);
    this.router.navigate(['result']);
  }

  setLinkToVideo(): void {
    const video: any = document.getElementById('scanVideo');
    const link = this.mediaServices.getVideoLink();
    if (link) {
      video.src = link;
    }
  }

  setCheckFile(): void {
    this.checkFile = !!this.mediaServices.getFile() && !!this.mediaServices.getVideoImages();
    //need added guard
    if(!this.checkFile) {
      this.router.navigate(['/']);
    }
    this.mediaServices.file.subscribe((value) => {
      this.checkFile = !!value;
    })
  }

  removeFile(): void {
    this.mediaServices.removeFile();
    this.router.navigate(['/']);
  }

  redoScan(): void {
    this.mediaServices.removeFile();
    this.router.navigate(['/start-recording']);
  }

  unSubCheckFiles(): void {
    this.mediaServices.file.unsubscribe();
  }

  ngOnDestroy() {
    //this.unSubCheckFiles();
  }

}
