<app-card>
  <header>Reset Password</header>
  <content>
    <form
      *ngIf="!emailSent; else emailSentTmp"
      [formGroup]="forgotPasswordForm"
      (ngSubmit)="onSubmit()"
    >
      <app-input
        [group]="forgotPasswordForm"
        type="email"
        controlName="email"
        placeholder="Email"
        autocomplete="email"
      ></app-input>
      <button
        pButton
        color="accent"
        type="submit"
        [disabled]="forgotPasswordForm.invalid"
        [loading]="loading"
        label="Send Reset Link"
      >
      </button>
    </form>

    <ng-template #emailSentTmp>
      <p class="message">
        An email has been sent to {{ forgotPasswordForm.value.email }} with a
        link to reset your password.
      </p>
    </ng-template>
  </content>
</app-card>
