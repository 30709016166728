import { Component } from '@angular/core';
import { QueryParamsService } from 'src/app/shared/service/query-params.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent {
  constructor(private queryParamService: QueryParamsService) {}
}
