import { Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { map } from 'rxjs/operators';
import { UserService } from 'src/app/api/services';
import { callbackParamterName } from 'src/app/constants';
import { updateAuthState, User } from 'src/app/repos/auth.repository';

@UntilDestroy()
@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
})
export class LoginPageComponent {
  loginForm = new UntypedFormGroup({
    email: new UntypedFormControl('', [Validators.required, Validators.email]),
    password: new UntypedFormControl('', [
      Validators.required,
      Validators.minLength(8),
    ]),
  });
  loading = false;

  hasGoBackButton = Boolean(
    this.route.snapshot.queryParams[callbackParamterName]
  );

  constructor(
    private userService: UserService,
    private snackBar: MatSnackBar,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  private getUrlForUser(user: User) {
    if (user.roles.some((role) => role.name === 'admin-user')) {
      return ['/admin'];
    }
    return ['/'];
  }

  goBack() {
    const callback = this.route.snapshot.queryParams[callbackParamterName];
    if (callback) {
      window.location.href = callback;
    }
  }

  onSubmit() {
    this.loading = true;
    this.userService
      .login({
        body: {
          email: this.loginForm.value.email,
          password: this.loginForm.value.password,
        },
      })
      .pipe(
        map((data) => data as { data: { access_token: string; user: User } }),
        map((res) => res.data),
        untilDestroyed(this)
      )
      .subscribe(
        (data) => {
          this.router.navigate(this.getUrlForUser(data.user), {
            queryParams: this.route.snapshot.queryParams,
            queryParamsHandling: 'merge',
          });
          updateAuthState({
            token: data.access_token,
            user: data.user,
          });
          this.loading = false;
        },
        (err) => {
          this.snackBar.open(
            err?.error?.message ?? 'Something went wrong',
            'Close',
            {
              duration: 2000,
            }
          );
          this.loading = false;
        }
      );
  }
}
