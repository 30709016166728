import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { ApiPath } from '../../constants/shared-enum';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  get apiUrl(): string {
    return this.config.apiUrl;
  }

  get token(): string {
    return this.config.token;
  }

  constructor(
    private http: HttpClient,
    @Inject('config') private config: any
  ) {}

  getHttpHeaders() {
    return {
      headers: new HttpHeaders({
        apikey: this.token,
      }),
    };
  }

  uploadFile(data: FormData): Observable<any> {
    const progressOptions = {
      reportProgress: true,
      observe: 'events',
    };

    const options: any = { ...this.getHttpHeaders(), ...progressOptions };

    return this.http.post<any>(
      this.apiUrl + ApiPath.UploadVideoUrl,
      data,
      options
    );
  }

  // get processing status/result
  getProcessingStatus(id: string | number): Observable<HttpResponse<any>> {
    const params = new HttpParams().set('id', id);
    return this.http.get(this.apiUrl + ApiPath.ProcessingVideoStatus, {
      ...this.getHttpHeaders(),
      observe: 'response',
      params,
    });
  }

  getImage(url: string): Promise<any> {
    return this.http.get(url, { responseType: 'blob' }).toPromise();
  }

  handleError(error: any) {
    return throwError(error);
  }
}
