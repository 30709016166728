import { Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FittingsService, UploadService } from 'src/app/api/services';
import { map } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Fitting, FittingListResponse } from 'src/app/shared/models';

@UntilDestroy()
@Component({
  selector: 'app-matching-list-page',
  templateUrl: './matching-list-page.component.html',
  styleUrls: ['./matching-list-page.component.scss'],
})
export class MatchingListPageComponent implements OnInit {
  isLoading = false;
  fittings: Fitting[] = [];

  constructor(
    private fittingsService: FittingsService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.loadFittings();
  }

  getStatus(fitting: Fitting) {
    if (!fitting.completed) {
      return 'match-loading'
    }
    return !fitting.result_size ? 'match-no-data' : 'match';
  }

  loadFittings() {
    this.isLoading = true;
    this.fittingsService
      .getFittingList()
      .pipe(
        map((res) => res as FittingListResponse),
        map((res) => res.data.fittings),
        untilDestroyed(this)
      )
      .subscribe(
        (completedFittings) => {
          this.isLoading = false;
          completedFittings.sort((a, b) => {
            const dateA = new Date(a.created_at);
            const dateB = new Date(b.created_at);
            return dateB.getTime() - dateA.getTime();
          });

          this.fittings = completedFittings;
          // make new load in 5 seconds if there are still fittings that are not completed
          if (this.fittings.some((fitting) => !fitting.completed)) {
            setTimeout(() => this.loadFittings(), 5000);
          }
        },
        (err) => {
          this.fittings = [];
          this.isLoading = false;
          this.snackBar.open('Error fetching fittings', 'OK', {
            duration: 3000,
          });
        }
      );
  }
}
