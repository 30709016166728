<div class="scan-wrapper">
  <div class="video-wrapper centered-wrapper">
    <div id="scan-wrapper" class="video-wrapper__semitransparent-frame">
      <video #scan id="scan" width="100%" height="100%" autoplay muted playsinline></video>
      <div class="video-introduction" *ngIf="showIntroduction">
          <p>
          Hold your phone in LANDSCAPE mode and start scanning.
          </p>
          <button 
            pButton
            *ngIf="showStartButton && (showError | async) === false"
            (click)="countTimeBeforeStart()"
            label="Start scanning"
          ></button>
      </div>
    </div>
  </div>
  <canvas id="camera-image" style="display:none;" width="1920" height="1080"></canvas>
  <canvas [class.hidden]="!showDebug" id="cv-canvas-image"></canvas>
  <div class="scan-images-instruction" *ngIf="(showError | async) === false && !showStartButton">
    <p *ngIf="showBeforeCount" class="count-text">{{beforeText}}</p>
    <div class="scan-images-instruction__text">
      <p *ngIf="imagesInstruction">
        {{imagesInstruction.text}}
      </p>
    </div>
    <div class="scan-images-instruction__image">
      <img *ngIf="imagesInstruction?.imageUrl" [src]="imagesInstruction?.imageUrl" alt="Instruction image">
    </div>
    <button
      *ngIf="imagesInstruction"
      pButton
      label="Cancel Scan"
      (click)="cancel()"
    ></button>
  </div>
  <app-small-tutorial class="small-tutorial" *ngIf="showSmallTutorial" (close)="onGetStarted()"></app-small-tutorial>
  <div *ngIf="(showError | async) === true" class="scan-wrapper__error centered-wrapper">
    <!--<p>Camera don't support current settings</p>-->
    <div class="scan-wrapper__error_info">
      <div class="images-wrapper">
        <img src="/assets/images/mobile/phone-portrait-orange.svg" alt="Phone">
        <img src="/assets/images/arrows/forward-orange-arrow.svg" alt="">
        <img src="/assets/images/mobile/phone-landscape-orange.svg" alt="Phone">
      </div>
      <p>Please, take your phone in landscape orientation</p>
    </div>
  </div>
</div>
