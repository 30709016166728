<app-card>
  <header>Sign in</header>
  <content>
    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
      <app-input
        [group]="loginForm"
        type="email"
        placeholder="Email"
        controlName="email"
        autocomplete="email"
      ></app-input>
      <app-input
        [group]="loginForm"
        placeholder="Password"
        controlName="password"
        type="password"
        autocomplete="current-password"
      ></app-input>
      <button
        pButton
        [loading]="loading"
        label="Sign in"
        type="submit"
        [disabled]="!loginForm.valid"
      >
      </button>

      <div class="flex items-center content-center">
        <span class="text-suttled">or</span>
        <button
          pButton
          class="p-button-text p-button-secondary-text"
          label="Sign up"
          type="button"
          [routerLink]="['/register']"
          queryParamsHandling="preserve"
        ></button>
      </div>

      <div class="line"></div>

      <button
        pButton
        class="p-button-text p-button-secondary-text"
        label="Restore password"
        type="button"
        [routerLink]="['/forgot-password']"
        queryParamsHandling="preserve"
      ></button>

      <button
        pButton
        *ngIf="hasGoBackButton"
        class="p-button-tint"
        label="Go back"
        (click)="goBack()"
        type="button"
      ></button>
    </form>
  </content>
</app-card>
