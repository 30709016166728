import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { callbackParamterName } from 'src/app/constants';

@Component({
  selector: 'app-main-page',
  templateUrl: './main-page.component.html',
  styleUrls: ['./main-page.component.scss'],
})
export class MainPageComponent {
  hasGoBackButton = Boolean(
    this.route.snapshot.queryParams[callbackParamterName]
  );

  imageUrl = this.route.snapshot.queryParams['imageUrl'];
  shoesName = this.route.snapshot.queryParams['vendor'];

  constructor(private router: Router, private route: ActivatedRoute) {}

  goBack() {
    const callback = this.route.snapshot.queryParams[callbackParamterName];
    if (callback) {
      window.location.href = callback;
    }
  }

  isRouteActive(route: string): boolean {
    return this.router.isActive(route, {
      queryParams: 'ignored',
      matrixParams: 'exact',
      fragment: 'exact',
      paths: 'exact',
    });
  }
}
