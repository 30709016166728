<ng-container *ngIf="group">
  <div class="p-field" [formGroup]="group">
    <input
      [class.p-invalid]="control?.invalid"
      pInputText
      [id]="controlName"
      [type]="type"
      [autocomplete]="autocomplete"
      [formControlName]="controlName"
      [placeholder]="placeholder"
    />
    <ng-container *ngIf="control?.touched">
      <small class="error p-error" *ngFor="let err of errors">
        {{ err }}
      </small>
    </ng-container>
  </div>
</ng-container>
