import { createStore, select, withProps } from '@ngneat/elf';
import { localStorageStrategy, persistState } from '@ngneat/elf-persist-state';

export type Role = {
  id: number;
  name: string; // client-user or admin-user
}

export type User = {
  id: number;
  email: string;
  email_verified: boolean;
  roles: Role[];
};

export interface AuthProps {
  token: string;
  user: User | null;
}

 const storeDef = createStore(
  { name: 'auth' },
  withProps<AuthProps>({ token: '', user: null })
);

export const store = persistState(storeDef, {
  key: 'auth',
  storage: localStorageStrategy,
});

export function updateAuthState(state: AuthProps) {
  storeDef.update(_ => ({
    ...state,
  }));
}

export function clearAuth() {
  storeDef.update(_ => ({
    token: '',
    user: null,
  }));
}

export const token$ = storeDef.pipe(select(state => state.token));
export const userRoles$ = storeDef.pipe(select(state => state.user?.roles ?? []));
