<app-card>
  <header [class.background-img]="!!imageUrl">
    <ng-container *ngIf="!imageUrl">
      <img src="assets/images/hello-emoji.png" alt="" class="hello-img" />
      <div class="welcome-text">Welcome back</div>
    </ng-container>
    <div class="header-img" *ngIf="imageUrl">
      <div class="text">
        <div class="header-text">You're measuring shoes</div>
        <div class="header-text header-text-lg">{{shoesName}}</div>
        <div class="header-text">
          Select the desired size or scan a new one.
        </div>
      </div>
      <img [src]="imageUrl" alt="" class="user-img" />
      <div class="background"></div>
    </div>
  </header>
</app-card>

<div class="page">
  <div class="tab--switch">
    <button
      pButton
      type="button"
      label="My scans"
      class="tab--switch__button p-button-sm"
      [ngClass]="{
        'p-button-tint': !isRouteActive('/'),
        'p-button-secondary': isRouteActive('/')
      }"
      [routerLink]="['/']"
      queryParamsHandling="preserve"
    ></button>
    <button
      pButton
      type="button"
      label="History matches"
      class="tab--switch__button p-button-sm"
      [ngClass]="{
        'p-button-tint': !isRouteActive('/matchings'),
        'p-button-secondary': isRouteActive('/matchings')
      }"
      [routerLink]="['/matchings']"
      queryParamsHandling="preserve"
    ></button>
  </div>

  <router-outlet></router-outlet>
  <div class="buttons-wrapper">
    <button
      *ngIf="hasGoBackButton"
      pButton
      (click)="goBack()"
      class="p-button-tint"
      type="button"
      label="Go back"
    ></button>
    <button
      pButton
      type="button"
      class="p-button-secondary"
      label="Add Scan"
      [routerLink]="['/start-recording']"
      queryParamsHandling="preserve"
    ></button>
  </div>
</div>
