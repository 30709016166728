import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { getStore } from '@ngneat/elf';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { clearAuth } from '../repos/auth.repository';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private router: Router, @Inject('config') private config: any) {}

  publicEndpoints = [
    `${this.config.apiUrl}/user/login`,
    `${this.config.apiUrl}/user/register`,
  ];
  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    // we look only on api urls
    if (!request.url.startsWith(this.config.apiUrl)) {
      return next.handle(request);
    }

    // skip public endpoints
    if (this.publicEndpoints.some((url) => request.url.startsWith(url))) {
      return next.handle(request);
    }

    const val = getStore('auth')?.getValue();
    const token = val?.token;
    if (token) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });
    }
    return next.handle(request).pipe(
      tap(
        () => {},
        (err) => {
          if (err.status === 401) {
            clearAuth();
            this.router.navigate(['/login'], {
              queryParamsHandling: 'merge',
            });
          }
        }
      )
    );
  }
}
