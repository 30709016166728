<app-card>
  <header>Reset password</header>
  <content>
    <form [formGroup]="resetPasswordForm" (ngSubmit)="onSubmit()">
      <app-input
        [group]="resetPasswordForm"
        placeholder="Password"
        controlName="password"
        type="password"
        autocomplete="new-password"
      >
      </app-input>
      <app-input
        [group]="resetPasswordForm"
        placeholder="Confirm Password"
        controlName="confirmPassword"
        type="password"
        autocomplete="new-password"
      ></app-input>
      <mat-error *ngIf="resetPasswordForm.hasError('passwordMismatch')">
        Passwords do not match
      </mat-error>
      <button
        mat-raised-button
        color="accent"
        type="submit"
        [disabled]="!resetPasswordForm.valid"
      >
        Reset Password
      </button>
    </form>
  </content>
</app-card>
