import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { token$ } from '../repos/auth.repository';

@Injectable({
  providedIn: 'root',
})
export class NotLoggedInGuard {
  constructor(private router: Router) {}

  canActivate(): Observable<boolean | UrlTree> {
    return token$.pipe(
      map((token) => !!token),
      map((isAuthenticated) =>
        isAuthenticated
          ? this.router.createUrlTree(['/'], {
              queryParamsHandling: 'merge',
            })
          : true
      )
    );
  }
}
