<app-header></app-header>
<div class="container">

  <div>
    <div class="instructions-info">
      <div class="player-wrapper recording-end" >
        <vg-player>
          <video [vgMedia]="$any(scanVideo)" #scanVideo id="scanVideo" preload="auto" controls>
            <source type="video/mp4">
          </video>
        </vg-player>
        <p *ngIf="!showRedo; else redoMessage">The scanning was successful. Please <span class="color-green">save</span> or cancel your scan</p>
        <ng-template #redoMessage>
          <p>The scanning was not successful. We can't detect the paper between your feet. Please <span class="color-red">redo</span> your scan</p>
        </ng-template>
      </div>

    </div>
    <div class="recording-buttons">
      <button *ngIf="showRedo" class="orange-button" (click)="redoScan()">Redo</button>
      <button *ngIf="!showRedo" class="orange-button" (click)="uploadFile()">Save</button>
      <p *ngIf="!showRedo && iosOS" class="save-warning">Please keep your screen on during uploading</p>
      <button *ngIf="checkFile" class="green-button remove-btn"  (click)="removeFile()">Undo</button>
    </div>
  </div>

</div>
