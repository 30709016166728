import { Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UserService } from 'src/app/api/services';
import { callbackParamterName } from 'src/app/constants';
import { passwordValidator, samePasswordValidator } from 'src/app/utils/validators';

@UntilDestroy()
@Component({
  selector: 'app-registration-page',
  templateUrl: './registration-page.component.html',
  styleUrls: ['./registration-page.component.scss'],
})
export class RegistrationPageComponent {
  regisrationForm = new UntypedFormGroup(
    {
      email: new UntypedFormControl('', [Validators.required, Validators.email]),
      password: new UntypedFormControl('', [
        Validators.required,
        Validators.minLength(8),
        passwordValidator,
      ]),
      confirmPassword: new UntypedFormControl('', [
        Validators.required,
      ]),
    },
    [samePasswordValidator('password', 'confirmPassword')]
  );

  loading = false;

  hasGoBackButton = Boolean(this.route.snapshot.queryParams[callbackParamterName]);

  constructor(
    private userService: UserService,
    private router: Router,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
  ) {}

  goBack() {
    const callback = this.route.snapshot.queryParams[callbackParamterName];
    if (callback) {
      window.location.href = callback;
    }
  }

  onSubmit() {
    this.loading = true;
    const { email, password } = this.regisrationForm.value;
    this.userService
      .register({ body: { email, password } })
      .pipe(untilDestroyed(this))
      .subscribe(
        () => {
          this.loading = false;
          this.snackBar.open('Registration successful', 'OK', {
            duration: 2000,
          });
          this.router.navigate(['/login']);
        },
        (err) => {
          this.loading = false;
          this.snackBar.open(
            err?.error?.message ?? 'Something went wrong',
            'Close',
            {
              duration: 2000,
            }
          );
        }
      );
  }
}
