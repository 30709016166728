import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { map } from 'rxjs/operators';
import { UploadService } from 'src/app/api/services';
import {
  StatusCode,
  UploadedVideo,
  UploadedVideoListResponse,
} from 'src/app/shared/models';

@UntilDestroy()
@Component({
  selector: 'app-measurements-list-page',
  templateUrl: './measurements-list-page.component.html',
  styleUrls: ['./measurements-list-page.component.scss'],
})
export class MeasurementsListPageComponent implements OnInit {
  isLoading = false;
  measures: UploadedVideo[] = [];

  constructor(
    private uploadService: UploadService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.loadList();
  }

  loadList() {
    this.isLoading = true;
    this.uploadService
      .uploadedVideoList()
      .pipe(
        map((res) => res as unknown as UploadedVideoListResponse),
        untilDestroyed(this),
        map((res) => res.data.uploaded_videos)
      )
      .subscribe(
        (res) => {
          const items =
            res?.filter(
              (item) =>
                item.processing_result.status_code === StatusCode.Success
            ) ?? [];
          items.sort((a, b) => {
            const dateA = new Date(a.created_at);
            const dateB = new Date(b.created_at);
            return dateB.getTime() - dateA.getTime();
          });
          this.isLoading = false;
          this.measures = items;
        },
        () => {
          this.measures = [];
          this.isLoading = false;
          this.snackBar.open('Error fetching fittings', 'OK', {
            duration: 3000,
          });
        }
      );
  }
}
