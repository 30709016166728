/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';


@Injectable({
  providedIn: 'root',
})
export class FittingsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getFittingList
   */
  static readonly GetFittingListPath = '/fittings';

  /**
   * Get user fitting list.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFittingList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFittingList$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, FittingsService.GetFittingListPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Get user fitting list.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getFittingList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFittingList(params?: {
    context?: HttpContext
  }
): Observable<{
}> {

    return this.getFittingList$Response(params).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Path part for operation addFitting
   */
  static readonly AddFittingPath = '/fittings';

  /**
   * Add user fitting.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addFitting()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addFitting$Response(params: {
    context?: HttpContext
    body: {

/**
 * Field
 */
'uploaded_video_id'?: number;

/**
 * Field
 */
'shoe_brand'?: string;

/**
 * Field
 */
'shoe_sku'?: string;

/**
 * Field
 */
'size_type'?: string;

/**
 * Field
 */
'link'?: string;
}
  }
): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, FittingsService.AddFittingPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Add user fitting.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addFitting$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addFitting(params: {
    context?: HttpContext
    body: {

/**
 * Field
 */
'uploaded_video_id'?: number;

/**
 * Field
 */
'shoe_brand'?: string;

/**
 * Field
 */
'shoe_sku'?: string;

/**
 * Field
 */
'size_type'?: string;

/**
 * Field
 */
'link'?: string;
}
  }
): Observable<{
}> {

    return this.addFitting$Response(params).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Path part for operation getFitting
   */
  static readonly GetFittingPath = '/fittings/{id}';

  /**
   * Get user fitting by id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFitting()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFitting$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, FittingsService.GetFittingPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Get user fitting by id.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getFitting$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFitting(params?: {
    context?: HttpContext
  }
): Observable<{
}> {

    return this.getFitting$Response(params).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Path part for operation deleteFitting
   */
  static readonly DeleteFittingPath = '/fittings/';

  /**
   * Delete user fitting list.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteFitting()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteFitting$Response(params?: {
    id: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, FittingsService.DeleteFittingPath + params?.id, 'delete');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Delete user fitting list.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteFitting$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteFitting(params?: {
    id: number;
    context?: HttpContext
  }
): Observable<{
}> {

    return this.deleteFitting$Response(params).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

}
