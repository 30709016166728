<header>
  <div class="header-image">
    <img
      (click)="goHome()"
      [src]="logoUrl"
      alt="FootSnap logo"
    />
  </div>
  <button
    *ngIf="isLoggedIn$ | async"
    pButton
    label="Logout"
    class="p-button-text p-button-secondary-text"
    [routerLink]="['/logout']"
  ></button>
</header>
