<div
  class="card match"
  *ngIf="
    type === 'match' || type === 'match-no-data' || type === 'match-loading'
  "
  [attr.data-type]="type"
>
  <header>
    <div class="card-header">
      <div class="card-title">
        {{ fitting?.uploaded_video?.name || "No name" }}
      </div>
      <div class="card-date">
        {{ fitting?.created_at | date : "dd.MM.yyyy(HH:mm)" }}
      </div>
    </div>

    <div class="separator-line"></div>
  </header>
  <div class="body">
    <ng-container *ngIf="type === 'match'">
      <div class="info-line">
        <div class="info-line-title">Model:</div>
        <div class="info-line-value">{{ fitting?.shoe_brand }}</div>
      </div>
      <div class="separator-line separator-line-xs"></div>
      <div class="header">Best size</div>
      <div class="info-line">
        <div class="info-line-title">
          {{ fitting?.size_type ?? "eur" | uppercase }}:
        </div>
        <div class="info-line-value">
          {{ fitting?.result_size }}
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="type === 'match-no-data'">
      <div class="no-data">
        <img src="assets/images/shrug-emoji.png" alt="" />
        <span class="no-data-text">
          Unfortunately,<br />
          no matches were found.
        </span>
      </div>
    </ng-container>
    <ng-container *ngIf="type === 'match-loading'">
      <div class="loading">
        <mat-spinner></mat-spinner>
        <span class="no-data-text"> Matching in progress... </span>
      </div>
    </ng-container>
  </div>

  <footer>
    <div class="separator-line"></div>
    <div class="card-buttons" [class.h-100]="type === 'match-no-data'">
      <ng-container *ngIf="type === 'match'">
        <div class="spacer"></div>
        <button
          pButton
          type="button"
          class="p-button-sm"
          label="Select Shoe"
          (click)="goToStore()"
        ></button>
      </ng-container>
      <ng-container *ngIf="type === 'match-no-data'">
        <button
          pButton
          type="button"
          class="p-button-text p-button-secondary-text p-button-sm"
          label="Close"
          [loading]="closing"
          (click)="close()"
        ></button>
      </ng-container>
    </div>
  </footer>
</div>
<div
  class="card measure"
  *ngIf="type === 'measure' && measurement"
  [attr.data-type]="type"
>
  <header>
    <div class="card-header">
      <span
        class="delete-icon"
        (click)="showDeleteScanDialog = true"
        src="assets/icons/delete.svg"
        title="Delete"
        alt=""
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 h-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </span>
      <div class="card-title">{{ measurement.name || "No name" }}</div>
      <img
        class="edit-icon"
        (click)="renameCard = true"
        src="assets/icons/edit.svg"
        title="Edit name"
        alt=""
      />
    </div>

    <div class="separator-line"></div>
  </header>

  <div class="body">
    <img src="assets/images/feets-emoji.png" />
    <div class="card-date">
      {{ measurement.created_at | date : "dd.MM.yyyy (HH:mm)" }}
    </div>
  </div>
  <footer>
    <div class="separator-line"></div>
    <div class="card-buttons">
      <button
        pButton
        type="button"
        label="Select Feet"
        [loading]="selectingFeet"
        *ngIf="canSelectShoe()"
        (click)="selectShoe()"
      ></button>
    </div>
  </footer>
</div>

<p-dialog
  header="Delete scan"
  [(visible)]="showDeleteScanDialog"
  [style]="{ minWidth: '25vw' }"
>
  <h5 class="mb-3">Are you sure you want to delete this scan?</h5>
  <ng-template pTemplate="footer">
    <div class="delete-dialog-buttons">
      <button
        pButton
        type="button"
        class="p-button-tint"
        label="No"
        (click)="showDeleteScanDialog = false"
      ></button>
      <button
        pButton
        type="button"
        class="p-button-primary"
        [loading]="deletingScan"
        label="Yes"
        (click)="deleteScan()"
      ></button>
    </div>
  </ng-template>
</p-dialog>

<p-dialog
  header="Rename scan"
  [(visible)]="renameCard"
  [style]="{ minWidth: '25vw' }"
>
  <h5 class="mb-3">Enter new scan name</h5>
  <input #text type="text" [value]="measurement?.name" pInputText />

  <ng-template pTemplate="footer">
    <button
      pButton
      type="button"
      class="p-button-secondary"
      [loading]="renaming"
      label="Save"
      (click)="rename(text)"
    ></button>
  </ng-template>
</p-dialog>
