<app-header></app-header>
<div class="container" [style]="{ display: !showCamera ? 'block' : 'none' }">
  <div class="player-wrapper" *ngFor="let item of instructionsVideo">
    <video class="center" controls (loadeddata)="onVideoLoaded()">
      <source [src]="item.video + '#t=0.001'" type="video/mp4" />
      <!-- add english captions-->
    </video>
  </div>
  <div class="instructions">
    <div class="scroll-down" [hidden]="!scrollDownVisible">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        stroke-width="2"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M19 13l-7 7-7-7m14-8l-7 7-7-7"
        />
      </svg>
    </div>
    <div class="instructions-title">Ready to start</div>
    <div class="instructions-subtitle">
      Please follow the instruction below to make sure we see your feet and
      ankles clearly.
    </div>
    <ul class="instructions-info__list">
      <li>
        <img src="/assets/icons/lamp.png" class="icon" alt="" />
        <div class="text">Good light conditions</div>
        <p-checkbox [(ngModel)]="form['light']" [binary]="true"></p-checkbox>
      </li>
      <li>
        <img src="/assets/icons/barefoot.jpeg" class="icon" alt="" />
        <div class="text">Be barefoot & roll up your pants</div>
        <p-checkbox [(ngModel)]="form['foot']" [binary]="true"></p-checkbox>
      </li>
      <li>
        <img src="/assets/icons/paper-a4.png" class="icon" alt="" />
        <div class="text">Put an A4 between your feet</div>
        <p-checkbox [(ngModel)]="form['paper']" [binary]="true"></p-checkbox>
      </li>
      <li>
        <div class="icon red">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z"
            />
          </svg>
        </div>
        <div class="text">Don't stand on paper</div>
        <p-checkbox [(ngModel)]="form['stand']" [binary]="true"></p-checkbox>
      </li>
    </ul>

    <div class="instructions-button-wrapper">
      <button
        #startButton
        class="orange-button"
        (click)="startRecording()"
        [disabled]="!(form['light'] && form['foot'] && form['paper'] && form['stand'])"
      >
        Start scanning
      </button>
    </div>
  </div>
</div>
<app-scan
  *ngIf="showCamera"
  (file)="saveFile($event)"
  (hideCamera)="hideCamera()"
></app-scan>
