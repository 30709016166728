import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { InstructionsFirstStepComponent } from './components/instructions-first-step/instructions-first-step.component';
import { InstructionsSecondStepComponent } from './components/instructions-second-step/instructions-second-step.component';
import { InstructionsThirdStepComponent } from './components/instructions-third-step/instructions-third-step.component';
import { HeaderComponent } from './shared/components/header/header.component';
import { FooterComponent } from './shared/components/footer/footer.component';
import { VgCoreModule } from '@videogular/ngx-videogular/core';
import { VgControlsModule } from '@videogular/ngx-videogular/controls';
import { VgOverlayPlayModule } from '@videogular/ngx-videogular/overlay-play';
import { VgBufferingModule } from '@videogular/ngx-videogular/buffering';
import { ScanComponent } from './shared/components/scan/scan.component';
import { MatDialogModule } from '@angular/material/dialog';
import { SelectCameraComponent } from './shared/components/select-camera/select-camera.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { UploadFileComponent } from './shared/components/upload-file/upload-file.component';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { LayoutComponent } from './layouts/layout/layout.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { ApiModule } from './api/api.module';
import { RegistrationPageComponent } from './pages/registration-page/registration-page.component';
import { CardComponent } from './shared/components/card/card.component';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { InputComponent } from './shared/components/input/input.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';

import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { FileUploadModule } from 'primeng/fileupload';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TableModule } from 'primeng/table';
import { MultiSelectModule } from 'primeng/multiselect';
import { DialogModule } from 'primeng/dialog';
import { CheckboxModule } from 'primeng/checkbox';
import { MainPageComponent } from './pages/main-page/main-page.component';
import { MeasurementsListPageComponent } from './pages/measurements-list-page/measurements-list-page.component';
import { MatchingListPageComponent } from './pages/matching-list-page/matching-list-page.component';
import { MeasureCardComponent } from './shared/components/measure-card/measure-card.component';
import { AdminPortalPageComponent } from './pages/admin-portal-page/admin-portal-page.component';
import { ApiConfiguration } from './api/api-configuration';
import { SmallTutorialComponent } from './shared/components/small-tutorial/small-tutorial.component';
import * as Sentry from '@sentry/angular-ivy';
import { Router } from '@angular/router';

@NgModule({
  declarations: [
    AppComponent,
    InstructionsFirstStepComponent,
    InstructionsSecondStepComponent,
    InstructionsThirdStepComponent,
    HeaderComponent,
    FooterComponent,
    ScanComponent,
    SelectCameraComponent,
    UploadFileComponent,
    LoginPageComponent,
    LayoutComponent,
    RegistrationPageComponent,
    CardComponent,
    ForgotPasswordComponent,
    InputComponent,
    ResetPasswordComponent,
    MainPageComponent,
    MeasurementsListPageComponent,
    MatchingListPageComponent,
    MeasureCardComponent,
    AdminPortalPageComponent,
    SmallTutorialComponent,
  ],
  imports: [
    ApiModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,
    MatDialogModule,
    HttpClientModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatFormFieldModule,
    MatCardModule,
    MatInputModule,
    MatButtonModule,
    // PrimeNG
    InputTextModule,
    ButtonModule,
    FileUploadModule,
    ProgressSpinnerModule,
    TableModule,
    MultiSelectModule,
    DialogModule,
    CheckboxModule,
    // Specify ng-circle-progress as an import
    NgCircleProgressModule.forRoot({
      // set defaults here
      radius: 33,
      outerStrokeWidth: 3,
      innerStrokeWidth: 3,
      outerStrokeColor: '#2A9D8F',
      innerStrokeColor: '#FFFFFF',
      animationDuration: 300,
      backgroundPadding: 0,
      animation: false,
      titleColor: '#FFFFFF',
      titleFontSize: '24px',
    }),
  ],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    {
      provide: ApiConfiguration,
      useFactory: (config: any) => {
        return {
          rootUrl: config.apiUrl,
        };
      },
      deps: ['config'],
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
