<div class="list-wrapper">
  <ng-container *ngIf="!isLoading; else loading">
    <ng-container *ngIf="fittings.length > 0; else noData">
      <app-measure-card
        *ngFor="let fitting of fittings"
        [type]="getStatus(fitting)"
        [fitting]="fitting"
        (closeFitting)="loadFittings()"
      ></app-measure-card>
      <div class="spacer"></div>
    </ng-container>
    <ng-template #noData>
      <div class="no-data">
        <img src="assets/images/ruler-emoji.png" />
        <div class="no-data-text">
          You don't have any match. <br />
          Click Add Scan to make your first scan and then match it with a shoe.
        </div>
      </div>
    </ng-template>
  </ng-container>
  <ng-template #loading>
    <div class="loading">
      <div class="loading-spinner">
        <mat-spinner></mat-spinner>
      </div>
    </div>
  </ng-template>
</div>
