import { Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/api/services';
import { passwordValidator, samePasswordValidator } from 'src/app/utils/validators';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent {
  resetPasswordForm = new UntypedFormGroup(
    {
      password: new UntypedFormControl('', [
        Validators.required,
        Validators.minLength(8),
        passwordValidator,
      ]),
      confirmPassword: new UntypedFormControl('', [
        Validators.required,
      ]),
    },
    [samePasswordValidator('password', 'confirmPassword')]
  );

  constructor(
    private userService: UserService,
    private router: Router,
    private routeSnapshot: ActivatedRoute,
    private snackBar: MatSnackBar
  ) {
    console.log(this.routeSnapshot.snapshot.queryParams);
  }

  onSubmit() {
    const queryParams = this.routeSnapshot.snapshot.queryParams;
    const {
      token, email
    } = queryParams;

    this.userService.resetPassword({
      token,
      email,
      password: this.resetPasswordForm.value.password,
    }).subscribe(
      () => {
        this.snackBar.open('Password reset successfully', 'Close', {
          duration: 2000,
        });
        this.router.navigate(['/login']);
      },
      (error) => {
        this.snackBar.open(error?.error?.message ?? 'Something went wrong', 'Close', {
          duration: 2000,
        });
      }
    );
  }
}
