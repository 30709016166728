import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { token$ } from '../repos/auth.repository';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class IsAuthenticatedGuard {
  constructor(private router: Router) {}
  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    return token$.pipe(
      map((token) => !!token),
      map((isAuthenticated) => {
        return isAuthenticated
          ? true
          : this.router.createUrlTree(['/login'], {
              queryParams: route.queryParams,
              queryParamsHandling: 'merge',
            });
      })
    );
  }
}
