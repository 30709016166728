import { Component, EventEmitter, Output } from '@angular/core';
import { interval } from 'rxjs';
import { map, takeWhile } from 'rxjs/operators';

const totalTimeMs = 8000;
const stepsCount = 7;

@Component({
  selector: 'app-small-tutorial',
  templateUrl: './small-tutorial.component.html',
  styleUrls: ['./small-tutorial.component.scss'],
})
export class SmallTutorialComponent {
  @Output() close = new EventEmitter<void>();

  tick$ = interval(totalTimeMs / stepsCount).pipe(
    map((x) => x + 1),
    takeWhile((x) => x <= stepsCount + 1)
  );

  getImage(step: number) {
    const imageId = step === stepsCount + 1 ? 1 : step;
    return `assets/images/small-tutorial/${imageId}.png`;
  }

  getText(step: number) {
    if (step === 1) {
      return `Your start position is (${step}/${stepsCount})`;
    }
    if (step === stepsCount) {
      return `Move to last position (${step}/${stepsCount})`;
    }

    if (step === stepsCount + 1) {
      return 'Yours start position';
    }

    return `Move to position (${step}/${stepsCount})`;
  }

  scanNow() {
    this.close.emit();
  }
}
