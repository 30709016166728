<div class="upload-wrapper centered-wrapper">
  <div class="position-fixed-cover upload-info">
    <h4>Your scan is being <span class="color-green">stored</span>, please wait.</h4>
    <div class="upload-info__progress">
      <mat-spinner mode="indeterminate"></mat-spinner>
      <p>{{progressUpload.percent + '%'}}</p>
    </div>
  </div>
  <div class="position-fixed-cover upload-overlay"></div>
</div>
