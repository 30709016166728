<div class="list-wrapper">
  <ng-container *ngIf="!isLoading; else loading">
    <ng-container *ngIf="measures.length > 0; else noData">
      <app-measure-card
        *ngFor="let measurement of measures"
        type="measure"
        [measurement]="measurement"
        (closeMeasurement)="loadList()"
      ></app-measure-card>
      <div class="spacer"></div>
    </ng-container>
    <ng-template #noData>
      <div class="no-data">
        <img src="assets/images/ruler-emoji.png" />
        <div class="no-data-text">
          You don't have a single scan. <br />
          Click Add Scan to make your first scan.
        </div>
      </div>
    </ng-template>
  </ng-container>
  <ng-template #loading>
    <div class="loading">
      <div class="loading-spinner">
        <mat-spinner></mat-spinner>
      </div>
    </div>
  </ng-template>
</div>
