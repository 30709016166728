import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {HelperService} from './shared/services/helper.service';
import {ApiService} from './shared/services/api.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'footsnap';

  constructor(private route: ActivatedRoute,
              private helperService: HelperService,
              private apiService: ApiService) {
  }

  ngOnInit() {
    this.route.queryParams
      .subscribe(params => {
          if (params.shopifyurl) {
            this.helperService.setShopifyUrl(params.shopifyurl + `?varId=${params.varId}`);
          }
        }
      );

    this.helperService.saveImages();
    this.helperService.preloadSounds();
  }
}
