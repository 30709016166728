<div class="background"></div>

<div class="container" *ngIf="tick$ | async as step">
  <div class="left">
    <div class="text">
      Turn your phone to landscape view and start scanning.
    </div>
    <div class="text">Your TOTAL scanning time is 30 sec.</div>
    <div class="text">
      {{getText(step)}}
      <img class="arrow" src="assets/images/small-tutorial/arrow.png" alt="" />
    </div>
    <button
      pButton
      type="button"
      label="SCAN NOW"
      (click)="scanNow()"
      [disabled]="step < 7"
    ></button>
  </div>
  <div class="right">
    <img [src]="getImage(step)" alt="" />
  </div>
</div>
