import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { InstructionsFirstStepComponent } from './components/instructions-first-step/instructions-first-step.component';
import { InstructionsSecondStepComponent } from './components/instructions-second-step/instructions-second-step.component';
import { InstructionsThirdStepComponent } from './components/instructions-third-step/instructions-third-step.component';
import { IsAdminUserGuard } from './guards/is-admin.guard';
import { IsAuthenticatedGuard } from './guards/is-authenticated.guard';
import { IsClientUserGuard } from './guards/is-client-user.guard';
import { LogoutGuard } from './guards/logout-guard.guard';
import { NotLoggedInGuard } from './guards/not-logged-in.guard';
import { LayoutComponent } from './layouts/layout/layout.component';
import { AdminPortalPageComponent } from './pages/admin-portal-page/admin-portal-page.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { MainPageComponent } from './pages/main-page/main-page.component';
import { MatchingListPageComponent } from './pages/matching-list-page/matching-list-page.component';
import { MeasurementsListPageComponent } from './pages/measurements-list-page/measurements-list-page.component';
import { RegistrationPageComponent } from './pages/registration-page/registration-page.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      // user section
      {
        path: '',
        data: { redirectTo: '/admin' },
        canActivate: [
          IsAuthenticatedGuard,
          IsClientUserGuard
        ],
        // user section
        children: [
          {
            path: '',
            component: MainPageComponent,
            children: [
              {
                path: '',
                component: MeasurementsListPageComponent,
              },
              {
                path: 'matchings',
                component: MatchingListPageComponent,
              }
            ]
          },
          { path: 'result', component: InstructionsThirdStepComponent },
          {
            path: 'video-recording',
            component: InstructionsSecondStepComponent,
          },
          { path: 'start-recording', component: InstructionsFirstStepComponent },
        ],
      },
      {
        path: 'admin',
        data: { redirectTo: '/' },
        canActivate: [
          IsAuthenticatedGuard,
          IsAdminUserGuard,
        ],
        children: [
          {
            path: '',
            component: AdminPortalPageComponent,
          }
        ],
      },
      {
        path: '',
        canActivate: [IsAuthenticatedGuard],
        children: [
          {
            path: 'logout',
            canActivate: [LogoutGuard],
            component: LoginPageComponent,
          }
        ]
      },
      // anonymous section
      {
        path: '',
        // only anonymous users can access this route
        canActivate: [NotLoggedInGuard],
        children:[
          { path: 'login', component: LoginPageComponent },
          { path: 'register', component: RegistrationPageComponent },
          { path: 'forgot-password', component: ForgotPasswordComponent },
          { path: 'user/reset-password', component: ResetPasswordComponent },
          { path: '**', redirectTo: 'login', pathMatch: 'full' },
        ]
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
