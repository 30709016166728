import { Component, OnInit } from '@angular/core';
import {catchError, map} from 'rxjs/operators';
import {HttpErrorResponse, HttpEventType} from '@angular/common/http';
import {of} from 'rxjs';
import {MediaService} from '../../services/media.service';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ApiService} from '../../services/api.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.scss']
})
export class UploadFileComponent implements OnInit {

  progressUpload = {
    progress: false,
    percent: '0'
  }

  constructor(
    private mediaServices: MediaService,
    private apiService: ApiService,
    public dialogRef: MatDialogRef<UploadFileComponent>,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.uploadFile();
  }

  uploadFile() {

    const data = new FormData();
    data.append('videoFile', this.mediaServices.getFile());
    data.append('partnerId', '1');
    //TODO
    data.append('manufacturerId', ''); //to add vendor from QR code
    data.append('productSku', ''); //to add sku from QR code
    data.append('shopUrl', ''); //to add
    data.append('productId', ''); //to add
    data.append('selectedVariantId', ''); //to add
    data.append('selectedVariantColor', ''); //to add

    this.mediaServices.getVideoImages()?.forEach((item: any) => {
    data.append('images[]', item);
    });

    this.progressUpload.progress = true;

    this.apiService.uploadFile(data).pipe(
      map(event => {
        switch (event.type) {
          case HttpEventType.UploadProgress:
            this.progressUpload.percent = (event.loaded * 98  / event.total).toFixed(1);
            break;
          case HttpEventType.Response:
            return event;
        }
      }),
      catchError((error: HttpErrorResponse) => {
        console.log(error);
        switch (true) {
          /*change in next sprint*/
         /* case error.status === 0:
            this.closeUploadModal('Your scan was not sent to processing.' +
              'Please check your internet connection, do a new scan and try again');
            break;*/
          case !!(error.error && error.error.message):
            this.closeUploadModal(error.error.message);
            break;
          default:
            /*maybe better default text here*/
            this.closeUploadModal(error.status.toString());
        }
       /*
        error.error && error.error.message ?
          this.closeUploadModal(error.error.message) : this.closeUploadModal(error.status.toString());*/
        return of(`Upload failed.`);
      })).subscribe((res) => {

      if (typeof (res) === 'object') {
        if(!res || !res.body || !res.body.data || !res.body.data.id) this.router.navigate(['/']);
        this.mediaServices.cleanFiles();
        this.mediaServices.setUploadId(res.body.data.id);
        this.closeUploadModal("success");
      }

    }, error => {
      if (typeof (error) === 'object') {
        this.closeUploadModal(error.status ? error.status : "Server error");
      } else {
        this.closeUploadModal("Server error");
      }
    })
  }

  closeUploadModal(result: string): void {
    this.progressUpload.progress = false;
    this.dialogRef.close(result);
  }

}
