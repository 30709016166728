import { Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UserService } from 'src/app/api/services';

@UntilDestroy()
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent {
  forgotPasswordForm = new UntypedFormGroup({
    email: new UntypedFormControl('', [Validators.required, Validators.email]),
  });

  emailSent = false;
  loading = false;

  constructor(
    private userService: UserService,
    private snackBar: MatSnackBar
  ) {}

  onSubmit() {
    this.loading = true;
    this.userService
      .forgotPassword({
        body: {
          email: this.forgotPasswordForm.value.email,
        },
      })
      .pipe(untilDestroyed(this))
      .subscribe(
        () => {
          this.loading = false;
          this.emailSent = true;
          this.snackBar.open('Please check your email', 'Close', {
            duration: 2000,
          });
        },
        (err) => {
          console.log(err);
          this.loading = false;
          this.snackBar.open(err?.error?.message ?? 'Something went wrong', 'Close', {
            duration: 2000,
          });
        }
      );
  }
}
