import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { userRoles$ } from '../repos/auth.repository';

@Injectable({
  providedIn: 'root',
})
export class IsAdminUserGuard {
  constructor(private router: Router) {}
  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    return userRoles$.pipe(
      map((roles) => {
        return roles.some((role) => role.name === 'admin-user');
      }),
      map((hasAccess) => {
        return hasAccess
          ? true
          : this.router.createUrlTree([route.data?.redirectTo ?? '/'], {
              queryParamsHandling: 'merge',
            });
      })
    );
  }
}
