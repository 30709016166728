import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { clearAuth } from '../repos/auth.repository';

@Injectable({
  providedIn: 'root',
})
export class LogoutGuard  {
  constructor(private router: Router) {}
  canActivate(): UrlTree {
    clearAuth();

    return this.router.createUrlTree(['/login']);
  }
}
