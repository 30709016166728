import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import {MatSnackBar} from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class MediaService {

  file: BehaviorSubject<Blob | null> = new BehaviorSubject<Blob | null>(null);
  uploadResponse: BehaviorSubject<string> = new BehaviorSubject<string>('');
  images: BehaviorSubject<Array<string> | null> = new BehaviorSubject<Array<string> | null>(null);
  stream: BehaviorSubject<MediaStream | null> = new BehaviorSubject<MediaStream | null>(null);

  uploadId: BehaviorSubject<string> = new BehaviorSubject<string>('');

  cameraOptions: MediaStreamConstraints = {
    video: {
      width: {exact: 1920},
      height: {exact: 1080},
      frameRate: {min: 30},
      facingMode: { exact: "environment" }
    },
    audio: false
  }

  constructor(private _snackBar: MatSnackBar) {
  }

  //plan b https://habr.com/ru/post/184926/
  getRequestFullScreenKey() {
    let goFullScreen = 'requestFullscreen';

    /*if ('mozRequestFullScreen' in document.documentElement) {
      goFullScreen = 'mozRequestFullScreen';
    } else*/ if ('webkitRequestFullscreen' in document.documentElement) {
      goFullScreen = 'webkitRequestFullscreen';
    } else if ('msRequestFullscreen' in document.documentElement) {
      goFullScreen = 'msRequestFullscreen';
    }
    return goFullScreen;
  }

  detectIOS(): boolean {
    return [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
      ].includes(navigator.platform)
      // iPad on iOS 13 detection
      || (navigator.userAgent.includes('Mac') && 'ontouchend' in document);
  }

  async blockOrientation(): Promise<void> {
    if (!this.checkMobile() || this.detectIOS()) return;
   /* if(this.detectIOS()) {
      this.showOrientationMessage();
      return;
    }*/
    //@ts-ignore
    await document.documentElement[this.getRequestFullScreenKey()]();
    try {
      await screen.orientation.lock('landscape');
    } catch(e) {
      console.log(e);
    }
  }

  showOrientationMessage(): void {
    this._snackBar.open("Please, take your phone in landscape orientation", 'x', {
      duration: 2000,
      verticalPosition: 'top',
    });
  }

  async unblockOrientation(): Promise<void> {
    if (!this.checkMobile() || this.detectIOS()) return;
    try {
      await screen.orientation.lock('portrait');
      await screen.orientation.lock('any');
    } catch(e) {
      console.log(e);
    }
    document.exitFullscreen();
  }

  checkMobile(): boolean {
    const nav = navigator.userAgent;
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(nav);
  }

  saveFile(file: Blob): void {
    this.file.next(file);
  }

  saveVideoImages(images: Array<string>): void {
    this.images.next(images);
  }

  getVideoImages(): Array<string> | null {
    return this.images.value;
  }

  removeFile(): void {
    this.file.next(null);
  }

  getFile(): any {
    return this.file.value;
  }

  getVideoLink(): string | null {
    if (!this.file.value) return null;
    return URL.createObjectURL(this.file.value);
  }

  setUploadResponse(response: string): void {
    this.uploadResponse.next(response);
  }

  getUploadResponse(): string {
    /*return 'success'; // FOR TEST PURPOSES ONLY*/
    return this.uploadResponse.value;
  }

  setUploadId(id: string): void {
    this.uploadId.next(id);
  }

  getUploadId(): string {
    return this.uploadId.value;
  }

  getCameraOptions(): MediaStreamConstraints {
    return this.cameraOptions;
  }

  setStream(stream: MediaStream): void {
    this.stream.next(stream);
  }

  getStream(): MediaStream | null {
    return this.stream.value;
  }

  detectPortrait(): boolean {
    return window.matchMedia("(orientation: portrait)").matches;
  }

  detectFireFox(): boolean {
    const sUsrAg = navigator.userAgent;
    return sUsrAg.indexOf('Firefox') > -1;
  }

  cleanFiles(): void {
    this.file = new BehaviorSubject<Blob | null>(null);
    this.images = new BehaviorSubject<Array<string> | null>(null);
    this.stream = new BehaviorSubject<MediaStream | null>(null);
  }
}
