import { AbstractControl, ValidatorFn } from '@angular/forms';

export const samePasswordValidator =
  (passwordControl: string, confirmPasswordControl: string): ValidatorFn =>
  (control: AbstractControl) => {
    const password: string = control.get(passwordControl)?.value ?? '';
    const confirmPassword: string =
      control.get(confirmPasswordControl)?.value ?? '';

    return password?.length > 0 &&
      confirmPassword?.length > 0 &&
      password !== confirmPassword
      ? { passwordMismatch: true }
      : null;
  };

export const passwordValidator: ValidatorFn = (control: AbstractControl) => {
  // minimum 1 lowercase letter [a-z]
  // minimum 1 upper case letter [A-Z]
  // minimum of 1 numeric character [0-9]
  const errorsList = [
    {
      pattern: /[a-z]/,
      message: 'Password must contain at least one lowercase letter',
    },
    {
      pattern: /[A-Z]/,
      message: 'Password must contain at least one uppercase letter',
    },
    {
      pattern: /[0-9]/,
      message: 'Password must contain at least one numeric character',
    },
  ];

  const error = errorsList.find((e) => !e.pattern.test(control.value));

  return error ? { password: error?.message } : null;
};
