/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

type file = Blob;

@Injectable({
  providedIn: 'root',
})
export class UploadService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation uploadVideo
   */
  static readonly UploadVideoPath = '/uploadVideo';

  /**
   * Upload Video.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadVideo()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadVideo$Response(params: {
    context?: HttpContext
    body: {

/**
 * Video file (avi,mp4,mov,webm)
 */
'videoFile': file;

/**
 * jpg,jpeg,png
 */
'images[]'?: Array<file>;

/**
 * Partner Id
 */
'partnerId': number;

/**
 * Manufacturer Id
 */
'manufacturerId'?: string;

/**
 * Model Id
 */
'modelId'?: string;

/**
 * Product Sku
 */
'productSku'?: string;
}
  }
): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, UploadService.UploadVideoPath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Upload Video.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `uploadVideo$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadVideo(params: {
    context?: HttpContext
    body: {

/**
 * Video file (avi,mp4,mov,webm)
 */
'videoFile': file;

/**
 * jpg,jpeg,png
 */
'images[]'?: Array<file>;

/**
 * Partner Id
 */
'partnerId': number;

/**
 * Manufacturer Id
 */
'manufacturerId'?: string;

/**
 * Model Id
 */
'modelId'?: string;

/**
 * Product Sku
 */
'productSku'?: string;
}
  }
): Observable<{
}> {

    return this.uploadVideo$Response(params).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Path part for operation uploadedVideoList
   */
  static readonly UploadedVideoListPath = '/uploaded-videos';

  /**
   * Get Video List.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadedVideoList()` instead.
   *
   * This method doesn't expect any request body.
   */
  uploadedVideoList$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UploadService.UploadedVideoListPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Get Video List.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `uploadedVideoList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  uploadedVideoList(params?: {
    context?: HttpContext
  }
): Observable<void> {

    return this.uploadedVideoList$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getUploadedVideo
   */
  static readonly GetUploadedVideoPath = '/uploaded-videos/{id}';

  /**
   * Upload Video.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUploadedVideo()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUploadedVideo$Response(params: {
    id: any;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UploadService.GetUploadedVideoPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Upload Video.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getUploadedVideo$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUploadedVideo(params: {
    id: any;
    context?: HttpContext
  }
): Observable<void> {

    return this.getUploadedVideo$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation deleteUploadedVideo
   */
  static readonly DeleteUploadedVideoPath = '/uploaded-videos/';

  /**
   * Upload Video.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteUploadedVideo()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteUploadedVideo$Response(params: {
    id: any;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UploadService.DeleteUploadedVideoPath + params?.id, 'delete');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Upload Video.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteUploadedVideo$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteUploadedVideo(params: {
    id: any;
    context?: HttpContext
  }
): Observable<void> {

    return this.deleteUploadedVideo$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation updateUploadedVideo
   */
  static readonly UpdateUploadedVideoPath = '/uploaded-videos/{id}';

  /**
   * Upload Video.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateUploadedVideo()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateUploadedVideo$Response(params: {
    id: any;
    context?: HttpContext
    body: {

/**
 * Name
 */
'name': string;
}
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UploadService.UpdateUploadedVideoPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Upload Video.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateUploadedVideo$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateUploadedVideo(params: {
    id: any;
    context?: HttpContext
    body: {

/**
 * Name
 */
'name': string;
}
  }
): Observable<void> {

    return this.updateUploadedVideo$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
