import { Injectable } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { callbackParamterName } from 'src/app/constants';

@Injectable({
  providedIn: 'root',
})
export class QueryParamsService {
  private queryParams = this.route.snapshot.queryParams;

  constructor(private route: ActivatedRoute) {}

  getQueryParams(): Params {
    return this.queryParams;
  }

  getShoeParams() {
    const params = this.getQueryParams();
    return {
      shoe_brand: params['vendor'],
      shoe_sku: params['sku'],
      size_type: params['sizeType'],
      link: params[callbackParamterName],
    };
  }
}
