import { Howl } from 'howler';
import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { fromEvent, interval, merge, Observable, of, Subject } from 'rxjs';
import { distinctUntilChanged, map, tap } from 'rxjs/operators';
import {
  SharedEnum,
  instructionsSteps,
  InstructionStep,
} from '../../../constants/shared-enum';
import { HelperService } from '../../services/helper.service';
import { MediaService } from '../../services/media.service';

declare var MediaRecorder: any;
declare var cv: any;

@Component({
  selector: 'app-scan',
  templateUrl: './scan.component.html',
  styleUrls: ['./scan.component.scss'],
})
export class ScanComponent implements OnInit, OnDestroy {
  showDebug = false;
  paperCount = 0;
  frameCount = 0;
  paperPercent = 0;

  status: 'recording' | 'stopped' = 'stopped';
  @Output() hideCamera: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() file = new EventEmitter<{ file: Blob; paperPercent: number }>();

  video: HTMLVideoElement | null = null;
  mediaFile: any;
  countTimeText: string = '00:' + (SharedEnum.TimeForScan / 1000).toString();
  countTimePercent: number = 0;
  imagesInstruction: null | InstructionStep = null;

  showStartButton = true;
  showSaveButton = false;

  // refactor when have a time
  smallTutorailInit = false;
  showSmallTutorial = false;

  showError = merge(
    fromEvent(window, 'resize'),
    fromEvent(window, 'orientationchange'),
    of(window.innerHeight > window.innerWidth)
  ).pipe(
    map(() => window.innerHeight > window.innerWidth),
    distinctUntilChanged(),
    tap((isVertical) => {
      if (!isVertical) {
        if (!this.smallTutorailInit) {
          this.smallTutorailInit = true;
          this.showSmallTutorial = true;
          // safari fix to hide tabs
          if (this.isSafari) {
            setTimeout(() => {
              window.scrollTo(0, 1);
            }, 100);
          }
        }
      }
    }),
  );

  start: Subject<boolean> = new Subject<boolean>();
  subscriptions: Map<string, any> = new Map<string, any>();

  images: any = [];
  isSafari: boolean = false;

  showBeforeCount = false;
  beforeText = '';

  showIntroduction = false;

  stream: MediaStream | null = null;

  constructor(
    private mediaServices: MediaService,
    private helperService: HelperService
  ) {
  }

  ngOnInit(): void {
    this.video = document.getElementById('scan') as HTMLVideoElement;
    this.openCamera();
    this.startScan();
    this.detectSafari();
  }


  onGetStarted(): void {
    this.showSmallTutorial = false;
    this.showIntroduction = true;
  }

  detectSafari(): void {
    this.isSafari = this.helperService.isSafariBrowser();
  }

  detectPapepr(video: HTMLVideoElement, outputDebug: boolean): Observable<boolean> {
    const subj = new Subject<boolean>();
    const outputCanvasId = 'cv-canvas-image';
    let height = 640
    let width = 480
    let canvasFrame = document.getElementById(outputCanvasId) as HTMLCanvasElement; // canvasFrame is the id of <canvas>
    let context = canvasFrame.getContext("2d") as CanvasRenderingContext2D;
    let src = new cv.Mat(height, width, cv.CV_8UC4);
    let dst = new cv.Mat(height, width, cv.CV_8UC1);
    const frameDelay = 250;
    function processVideo() {
      context.drawImage(video, 0, 0, width, height);
      src.data.set(context.getImageData(0, 0, width, height).data);
      cv.cvtColor(src, dst, cv.COLOR_RGBA2GRAY);
      // cv.canny(dst, dst, 50, 100, 3, false);
      cv.Canny(dst, dst, 70, 100, 3, false);
      // cv detect rectangles on image
      let contours = new cv.MatVector();
      let hierarchy = new cv.Mat();
      cv.findContours(dst, contours, hierarchy, cv.RETR_EXTERNAL, cv.CHAIN_APPROX_TC89_L1);
      // cv.findContours(dst, contours, hierarchy, cv.RETR_CCOMP, cv.CHAIN_APPROX_SIMPLE);
      let isDetected = false;
      for (let i = 0; i < contours.size(); ++i) {
        let cnt = contours.get(i);
        let rect = cv.boundingRect(cnt);
        // if contour is too small, it is likely a noise, remove it
        if (rect.width < 100 || rect.height < 100) {
          continue;
        }
        isDetected = true;
        if (outputDebug) {
          let rectangleColor = new cv.Scalar(255, 255, 0);
          let point1 = new cv.Point(rect.x, rect.y);
          let point2 = new cv.Point(rect.x + rect.width, rect.y + rect.height);
          cv.rectangle(dst, point1, point2, rectangleColor, 3, cv.LINE_AA, 0);
          console.log('rect detected', rect);
        }
      }
      contours.delete();
      hierarchy.delete();

      subj.next(isDetected);

      if (outputDebug) {
        try {
          cv.imshow(outputCanvasId, dst);
        } catch (error) {

        }
      }
      // schedule next one.
      requestAnimationFrame(() => {
        setTimeout(processVideo, frameDelay);
      });
    }
    // schedule first one.
    setTimeout(processVideo, 0);
    requestAnimationFrame(() => {
      processVideo();
    });

    return subj.asObservable();
  }

  async openCamera(): Promise<void> {
    this.stream = this.mediaServices.getStream();

    const video: HTMLVideoElement = document.querySelector('#scan') as HTMLVideoElement;
    //only for test
    video.setAttribute('autoplay', '');
    video.setAttribute('muted', '');
    video.setAttribute('playsinline', '');

    const detected$ = this.detectPapepr(video, true);

    const paperDetectSub = detected$.subscribe((detected) => {
      // console.log('detected', detected);
      if (this.status === 'recording') {
        if (detected === true) {
          this.paperCount += 1;
        }
        console.log('frame');
        this.frameCount++;
      }
    });
    this.subscriptions.set('paperDetect', paperDetectSub);

    if (!video) return;
    if ('srcObject' in video) {
      video.srcObject = this.stream;
    } else {
      // Avoid using this in new browsers, as it is going away.
      // @ts-ignore
      video.src = window.URL.createObjectURL(stream);
    }

    /*this.video.captureStream = this.video.captureStream || this.video.mozCaptureStream;*/
  }

  startScan(): void {
    const subscribe = this.start.subscribe((start) => {
      this.showStartButton = false;
      if (start) {
        /*const stream = this.getStream();*/
        const videoSrc = this.video?.srcObject as MediaStream;

        this.makeVideo(videoSrc).then(recordedChunks => {
          const type = recordedChunks[0].type;//this.detectType().includes('video/webm') ? 'video/webm' :
          // (this.detectType().includes('video/quicktime') ? 'video/quicktime' : 'video/mp4');
          const blobFile = new Blob(recordedChunks, { type: type.split(';')[0] });
          const fileName = 'video.' + (blobFile.type.includes('video/quicktime') ? 'mov' : blobFile.type.split('/')[1]);
          this.mediaFile = new File([blobFile], fileName, { type: blobFile.type });
          console.log(this.mediaFile.name);
          this.saveRecording();
        }).catch();
      }
    });

    this.subscriptions.set('startScan', subscribe);
  }

  startRecording(): void {
    this.start.next(true);
    if (!this.subscriptions.get('start')) {
      this.subscriptions.set('start', this.start);
    }
  }

  makeVideo(stream: MediaStream) {
    let recorder = new MediaRecorder(stream);

    let data: any = [];


    recorder.ondataavailable = (event: any) => data.push(event.data);
    recorder.start();

    this.status = 'recording';
    this.frameCount = 0;
    this.paperCount = 0;
    this.paperPercent = 0;


    let stopped = new Promise((resolve, reject) => {
      recorder.onstop = resolve;
      recorder.onerror = (event: any) => reject(event.name);
    });

    let recorded = this.wait().then(
      () => recorder.state == 'recording' && recorder.stop()
    );

    return Promise.all([
      stopped
    ])
    .then(() => {
      this.paperPercent = this.paperCount / this.frameCount * 100;
      this.paperPercent = isNaN(this.paperPercent) ? 0 : this.paperPercent;
      console.log('paperPercent', this.paperPercent);
      console.log('paperCount', this.paperCount);
      console.log('frameCount', this.frameCount);
      this.status = 'stopped';
    })
    .then(() => data);
  }

  //need add 1 second for timer, because we use interval
  wait(delayInMS: any = (SharedEnum.TimeForScan + 1000)) {
    this.countTime();

    this.showImageInstruction();
    return new Promise(resolve => setTimeout(resolve, delayInMS));
  }

  countTime(): void {
    let countTime = SharedEnum.TimeForScan;
    const initInterval = interval(1000).subscribe(() => {
      if (countTime > 0) {
        countTime -= 1000;
        let seconds = countTime / 1000;
        this.countTimeText = seconds < 10 ? ('00:0' + seconds.toString()) : ('00:' + seconds.toString());
        this.countTimePercent = 100 - (countTime / SharedEnum.TimeForScan) * 100;
        this.takePhoto(seconds);
      } else {
        this.subscriptions.get('countTime').unsubscribe();
      }
    });
    this.subscriptions.set('countTime', initInterval);
  }

  countTimeBeforeStart(): void {
    const video = document.querySelector('#scan') as HTMLVideoElement;
    if (video.paused) {
      video.play();
    }
    this.showStartButton = false;
    // 3 seconds before start functionality
    let countTime = 3000;
    //need improve
    /*this.imagesInstruction.showText = false;
    this.imagesInstruction.showLeg = false;*/
    this.showBeforeCount = true;
    this.beforeText = (countTime/1000).toString();
    this.showIntroduction = false;
    this.playSound('silence');
    const initInterval = interval(1000).subscribe(() => {
      countTime -= 1000;
      if (countTime > 0) {
        this.beforeText = countTime/1000 ? (countTime/1000).toString() : '';
      } else {
        this.subscriptions.get('countTimeBefore').unsubscribe();
        this.showBeforeCount = false;
        this.startRecording();
      }
    });
    this.subscriptions.set('countTimeBefore', initInterval);
  }

  playSound(url: string): void {
    const sound = this.helperService.soundMap.get(url);
    if (sound) {
      sound.play();
    }
  }

  showImageInstruction(): void {
    let countTime = SharedEnum.TimeForScan;
    let frameLength = +(countTime / instructionsSteps?.length).toFixed(0);
    this.setInstruction(instructionsSteps[0]);
    const initInterval = interval(frameLength).pipe(map(c => c + 1)).subscribe((i) => {
      this.setInstruction(instructionsSteps[i]);
      if (countTime > 0) {

      } else {
        this.subscriptions.get('imageInstruction').unsubscribe();
        this.setInstruction(null);
      }
    });

    this.subscriptions.set('imageInstruction', initInterval);
  }

  setInstruction(step: InstructionStep | null): void {
    this.imagesInstruction = step;
    if (step?.soundUrl) {
      this.playSound(step.soundUrl);
    }
  }

  saveRecording(): void {
    this.saveImages(this.images);
    this.file.emit({
      file: this.mediaFile, paperPercent: this.paperPercent
    });
  }

  ngOnDestroy(): void {
    this.clearSubscriptions();
    // close camera
    this.stream?.getTracks().forEach((track) => {
      track.stop();
    });
  }

  clearSubscriptions(): void {
    Array.from(this.subscriptions).forEach((sub) => {
      sub[1].unsubscribe();
    })
  }

  cancel(): void {
    this.hideCamera.emit(true);
  }

  takePhoto(seconds: number): void {
    const canvas = document.getElementById('camera-image');
    //@ts-ignore
    canvas.getContext('2d').drawImage(this.video, 0, 0, 1920, 1080);

    //@ts-ignore
    const blobImage = canvas.toDataURL('image/png');
    const imageName = seconds + 'seconds.png';

    const imageFile = this.dataURLtoFile(blobImage, imageName);
    this.images.push(imageFile);
  }

  dataURLtoFile(dataurl: any, filename: any) {

    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, {type: mime});
  }

  saveImages(images: Array<string>): void {
    this.mediaServices.saveVideoImages(images);
  }

  detectType(): string  {
    /*this.detectPortrait();*/
    const types = [
      'video/webm;codecs=vp8,opus',
      'video/webm;codecs=avc1.42E01E',
      'video/webm;codecs=vp8',
      'video/webm;codecs=vp9',
      'video/webm;codecs=vp8.0',
      'video/webm;codecs=vp9.0',
      'video/webm;codecs=h264',
      'video/webm;codecs=H264',
      'video/webm;codecs=avc1',
      'video/WEBM;codecs=VP8,OPUS',
      'video/webm;codecs=vp9,opus',
      'video/webm;codecs=vp8,vp9,opus',
      'video/webm;codecs=h264,opus',
      'video/webm;codecs=h264,vp9,opus',
      'video/webm',
      'video/mp4;codecs=vp09.00.50.08',
      'video/mp4;codecs=vp08',
      'video/mp4;codecs=hev1.1.6.L93.B0',
      'video/quicktime;codecs=h264,opus',
      'video/quicktime;codecs:h264',
      'video/quicktime;codecs=avc1.4d002a',
      "video/mp4;codecs=avc1",
      'video/mp4'
      /*'video/mp4;codecs=avc1.4d002a',
      'video/mp4;codecs:h264'*/
    ]

    const type = types.find((item) => MediaRecorder.isTypeSupported(item));
    return type ? type : 'video/webm;codecs=vp8,opus';
  }
}
