import { Injectable } from '@angular/core';
import { Howl } from 'howler';
import {BehaviorSubject} from 'rxjs';
import {ImagesUrlForPreload} from '../../constants/shared-enum';
import {ApiService} from './api.service';

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  shopifyUrl: BehaviorSubject<string> = new BehaviorSubject<string>('');
  images: Map<string, string> = new Map();
  soundMap = new Map<string, Howl>();

  constructor(private apiService: ApiService) { }

  setShopifyUrl(url: string): void {
    this.shopifyUrl.next(url)
  }

  getShopifyUrl(): string {
    return this.shopifyUrl.value;
  }

  saveShopifyURL(url: string): void {
    localStorage.setItem('shopifyURL', url);
  }

  getStorageShopifyURL(): string | null {
    return localStorage.getItem('shopifyURL');
  }

  saveVideoID(id: string): void {
    localStorage.setItem('videoID', id);
  }

  removeVideoID(): void {
    localStorage.removeItem('videoID');
  }

  getVideoID(): string | null {
    return localStorage.getItem('videoID');
  }

  isSafariBrowser(): boolean {
    let ua = navigator.userAgent || navigator.vendor;
    // Safari Browsers do not have Chrome in UA, but Chrome Browsers have both Chrome and Safari on OSX
    return ua.indexOf('Safari') !== -1 && ua.indexOf('Chrome') === -1;
  };

  saveImages(): void {
    ImagesUrlForPreload.forEach( (url) => {
      this.preloadImage(url);
      })
  }

  async preloadImage(url: string): Promise<any> {
    const imageBlob = await this.apiService.getImage(url);
    if(!imageBlob) return;
    const reader = new FileReader();
    return new Promise((resolve, _reject) => {
      reader.onloadend = () => {
        this.saveBlobImage(url, reader.result as string);
        resolve(reader.result as string);
      };
      if (imageBlob) {
        reader.readAsDataURL(imageBlob);
      }
    });
  }

  preloadSounds() {
    const urls = ['silence', '1', '2', '3', '4', '5', '6', '7'];

    for (const url of urls) {
      const sound = new Howl({
        src: [`assets/media/scanning/${url}.mp3`],
        volume: 1,
      });

      this.soundMap.set(url, sound);
    }
  }

  saveBlobImage(url: string, image: string): void {
    this.images.set(url, image);
  }
}
