import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MediaService} from '../../shared/services/media.service';
import {UploadResult} from '../../interfaces/upload-result';
import {ProcessingResult} from "../../interfaces/processing-result";
/*import {UploadResultDefault, UploadResultError, UploadResultSuccess} from '../../constants/shared-enum';*/
import {UploadVideoInterface} from '../../interfaces/api-interface';
import {
  catchError,
  concatMap,
  delay,
  filter,
  ignoreElements,
  map,
  repeat,
  startWith,
  switchMap,
  take,
  takeUntil
} from 'rxjs/operators';
import {HttpErrorResponse, HttpEventType} from '@angular/common/http';
import {ApiService} from '../../shared/services/api.service';
import {interval, of, Subject} from "rxjs";
import {MatSnackBar} from "@angular/material/snack-bar";
import { PushNotificationsService } from "../../shared/services/push-notifications.service";
import {HelperService} from '../../shared/services/helper.service';
import { trigger, state, style, animate, transition } from '@angular/animations';
import {UploadFileComponent} from '../../shared/components/upload-file/upload-file.component';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-instructions-third-step',
  templateUrl: './instructions-third-step.component.html',
  styleUrls: ['./instructions-third-step.component.scss'],
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({ opacity: 0 }),
            animate('2s ease-out',
              style({opacity: 1 }))
          ]
        ),
        transition(
          ':leave',
          [
            style({opacity: 1 }),
            animate('2s ease-in',
              style({opacity: 0 }))
          ]
        )
      ]
    )
  ]
})
export class InstructionsThirdStepComponent implements OnInit {
  //For test changed status to success
  status: string = 'success';
  //For test changed processingResult.status to pending|failed|success
  processingResult: ProcessingResult = {
    status: '',
    processingStep: '',
    errorMessage: '',
    shoeSize: '',
    alternativeShoeSize: '',
    warningMessage: '',
    shoeWidth: ''
  };
  showPermission: boolean = true;
  saveStorage: boolean = false;
  private stopPollingProcessingResult = new Subject();
  disabledSiteLink: boolean = false;
  videoID: string | null = '';
  showButtonLoading = false;
  responseCode: string = '';

  constructor(private mediaService: MediaService,
              private apiService: ApiService,
              private snackBar: MatSnackBar,
              private notificationService: PushNotificationsService,
              public router: Router,
              public dialog: MatDialog,
              public helperService: HelperService) {
  }

  ngOnInit(): void {
    this.setUploadResult();
    this.checkShopifyURL();
    this.videoID = this.getVideoID();
  }

  setUploadResult(): void {
    this.status = this.mediaService.getUploadResponse();
    const videoID = this.helperService.getVideoID();
    if(this.status !== "0" && !this.status && videoID) {
      this.status = 'success';
    }
    if (this.status) {

      // if we have uploaded video file
      if (this.status === 'success') {

        const id = this.mediaService.getUploadId() ? this.mediaService.getUploadId() : videoID;
        this.videoID = this.mediaService.getUploadId() ? this.mediaService.getUploadId() : videoID;

        const passAndWait = (data: any) => interval(30000).pipe(
          take(1),
          ignoreElements(), // this is needed to discard interval numbers
          startWith(data),
        )

        // start asking for a processing status
        //@ts-ignore
        this.apiService.getProcessingStatus(id).pipe(
          concatMap((data) => passAndWait(data)),
          repeat(Infinity),
          takeUntil(this.stopPollingProcessingResult),
        ).subscribe((response: any) => {
          // prepare success processing data and show it
          if (typeof (response) === 'object') {
            if(!response || !response.body || !response.body.data) this.router.navigate(['/']);

            this.processingResult = response.body.data;

            if(this.showPermission) this.askPermission();
            //@ts-ignore
            if(!this.saveStorage) this.saveDataInStorage(id);

            //need check than stop
            if(response.body.data.status === 'success' || response.body.data.status === 'failed') {
              // push notification
              this.notificationService.generateNotification([{
                'title': 'To Do Task',
                'alertContent': 'This is Fifth Alert'
              }]);
              this.stopPollingProcessingResult.next();
              localStorage.removeItem('videoID');
              localStorage.removeItem('uploadId');
              localStorage.removeItem('shopifyURL');
            }
          }
        }, (error: HttpErrorResponse) => {
          console.log(error);
          if(error.status === 0) {
            this.status = '0';
          }
          this.processingResult.status = 'failed'
          if(this.helperService.getVideoID()) {
            this.helperService.removeVideoID();
          }
        });
      }
    } else {
      this.router.navigate(['/']);
    }
  }

  retryProcessing() {
    this.showButtonLoading = true;
    const id = this.mediaService.getUploadId() ? this.mediaService.getUploadId() : this.helperService.getVideoID();

    if(!id) {
      this.router.navigate(['/']);
      return;
    }

    const passAndWait = (data: any) => interval(30000).pipe(
      take(1),
      ignoreElements(),
      startWith(data),
    )

    this.apiService.getProcessingStatus(id).pipe(
      concatMap((data) => passAndWait(data)),
      repeat(Infinity),
      takeUntil(this.stopPollingProcessingResult),
      filter((response: any) => typeof (response) === 'object'),
    ).subscribe((response: any) => {
        this.status = 'success';
        this.showButtonLoading = false;
        if(!response || !response.body || !response.body.data) this.router.navigate(['/']);

        this.processingResult = response.body.data;

        if(this.showPermission) this.askPermission();
        if(!this.saveStorage) this.saveDataInStorage(id);

        if(response.body.data.status === 'success' || response.body.data.status === 'failed') {
          this.notificationService.generateNotification([{
            'title': 'To Do Task',
            'alertContent': 'This is Fifth Alert'
          }]);
          this.stopPollingProcessingResult.next();
          localStorage.removeItem('videoID');
          localStorage.removeItem('shopifyURL');
      }
    }, (error: HttpErrorResponse) => {
      if(error.status === 0) {
        this.status = '0';
      }
      this.showButtonLoading = false;
      // show failed processing screen
      /*this.status = error.statusText;*/
      this.processingResult.status = 'failed'
      if(this.helperService.getVideoID()) {
        this.helperService.removeVideoID();
      }
    });
  }

  askPermission() {
    this.showPermission = false;
    setTimeout(()=> {
      // request permission to show notifications
      this.notificationService.requestPermission();
    }, 2000);
  }

  saveDataInStorage(id: string) {
    this.helperService.saveVideoID(id);
    const url = this.helperService.getShopifyUrl();
    if(url) {
      this.helperService.saveShopifyURL(url);
    }
    this.saveStorage = true;
  }

  checkToStartRecording(checkAlternativeShoeSize: boolean): void {
    checkAlternativeShoeSize ? this.startRecording() : this.goToSite();
  }

  startRecording() {
    this.router.navigate(['/start-recording']);
  }

  goToSite(): void {
    let params = '';
    switch (true) {
      case (!!this.processingResult.shoeSize):
        params = '&footshoesize=' + this.processingResult.shoeSize;
        break;
      case (!!this.processingResult.alternativeShoeSize):
        params = '&footshoesize=' + this.processingResult.alternativeShoeSize;
        break;
    }

    if(!!this.processingResult.shoeWidth) {
      params += '&footshoewidth=' + this.processingResult.shoeWidth;
    }
    console.log(this.helperService.getShopifyUrl() + params);
    const url = this.helperService.getShopifyUrl() ? this.helperService.getShopifyUrl() : this.helperService.getStorageShopifyURL();
    window.location.href = url + params;
  }

  checkShopifyURL(): void {
    const url = this.helperService.getShopifyUrl() ? this.helperService.getShopifyUrl() : this.helperService.getStorageShopifyURL();
    this.disabledSiteLink = !url;
  }

  ngOnDestroy() {
    this.stopPollingProcessingResult.next();
  }

  getVideoID(): string {
    const id = this.mediaService.getUploadId() ? this.mediaService.getUploadId() : this.helperService.getVideoID();
    return id ? id : '';
  }

  uploadFile() {
    if(!this.mediaService.getFile()) {
      this.snackBar.open("Please, make video", 'x', {
        duration: 2000,
        verticalPosition: 'top',
      });
      return;
    }

    let dialogRef = this.dialog.open(UploadFileComponent, {
      hasBackdrop: true
    });

    dialogRef.afterClosed().subscribe(res => {
      this.mediaService.setUploadResponse(res);
      this.setUploadResult();
    })
  }
}
