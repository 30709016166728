export const allColumns = [
  { field: 'id', header: 'ID' },
  { field: 'shoe_brand', header: 'Shoe Brand' },
  { field: 'shoe_manufacturer_id', header: 'Shoe Manufacturer ID' },
  { field: 'shoe_last_manufacturer_id', header: 'Shoe Last Manufacturer ID' },
  { field: 'shoe_retailer_id', header: 'Shoe Retailer ID' },
  { field: 'shoe_sku', header: 'Shoe SKU' },
  { field: 'margin_shoelast_length', header: 'Margin Shoe Last Length' },
  { field: 'margin_shoelast_width', header: 'Margin Shoe Last Width' },
  { field: 'margin_shoelast_instep', header: 'Margin Shoe Last Instep' },
  {
    field: 'margin_shoelast_circumferences',
    header: 'Margin Shoe Last Circumferences',
  },
  { field: 'margin_shoelast_height', header: 'Margin Shoe Last Height' },
  {
    field: 'offset_shoelast_length_toebox',
    header: 'Offset Shoe Last Length Toebox',
  },
  { field: 'upper_material_1', header: 'Upper Material 1' },
  { field: 'upper_material_2', header: 'Upper Material 2' },
  { field: 'upper_material_3', header: 'Upper Material 3' },
  { field: 'shrinking', header: 'Shrinking' },
  { field: 'construction', header: 'Construction' },
  { field: 'style', header: 'Style' },
  { field: 'wear', header: 'Wear' },
  { field: 'contrafor', header: 'Contrafor' },
  { field: 'type', header: 'Type' },
  { field: 'activity', header: 'Activity' },
  { field: 'heel_type', header: 'Heel Type' },
  { field: 'heel_height', header: 'Heel Height' },
  { field: 'heel_net_height', header: 'Heel Net Height' },
  { field: 'size_length_master_unit', header: 'Size Length Master Unit' },
  { field: 'size_length_master', header: 'Size Length Master' },
  { field: 'size_width_master_unit', header: 'Size Width Master Unit' },
  { field: 'size_width_master', header: 'Size Width Master' },
  { field: 'source_of_data', header: 'Source of Data' },
  { field: 'date_of_record', header: 'Date of Record' },
  { field: 'version', header: 'Version' },
  { field: 'last_cad.length', header: 'Last CAD Measurement Length' },
  { field: 'last_cad.width', header: 'Last CAD Measurement Width' },
  { field: 'last_cad.instep', header: 'Last CAD Measurement Instep' },
  { field: 'last_cad.circumference', header: 'Last CAD Measurement Circumference' },
  { field: 'last_cad.height', header: 'Last CAD Measurement Height' },
  { field: 'created_at', header: 'Created At', type: 'date' },
  { field: 'updated_at', header: 'Updated At', type: 'date' },
  { field: 'deleted_at', header: 'Deleted At', type: 'date' },
];
